@import '../../../config';

.admin-userDetail {
  display: flex;
  justify-content: flex-start;
}

.admin-userDetail-panelCont {
  min-width: 180px;
  width: 25%;
  max-width: 250px;
}

.user-info-panel {
  width: 100%;
  background-color: white;
  padding: 15px 2%;
  border-radius: 25px;
  color: #091817;

  .user-info-photo img {
    border-radius: 50%;
    display: block;
    width: 174px;
    height: 174px;
    margin: 10px auto;
    border: 12px solid rgba(65, 174, 165, 0.15);
  }

  .user-info-name {
    font-size: 24px;
    text-align: center;
  }

  .user-info-region {
    margin: 3px 0;
    text-align: center;
  }

  .user-info-city {
    margin: 3px 0;
    text-align: center;
  }

  .user-info-name,
  .user-info-date {
    display: block;
  }

  &-mobile {
    display: none;
  }

  .user-info {
    padding: 10px 20px;
  }

  .user-info-data {
    padding-top: 10px;
  }

  .user-info-title {
    font-weight: bold;
  }

  .user-info-edit-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .user-info-edit-button-container {
    width: 90%;
    margin-top: 20px;
  }

  .user-info-edit-button {
    width: 100%;
  }

  .admin-omama-clientcount-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 5px;
  }

  .admin-omama-clientcount {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $base_color;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0 auto;
  }

  .admin-omama-clientcount-text {
    color: $base_color;
    font-size: 0.9em;
  }

  .user-info-edit-buttons-container-mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .admin-userDetail {
    flex-direction: column;
    &-panelCont {
      min-width: unset;
      width: 100%;
      max-width: unset;
    }
  }

  .user-info-panel {
    display: none;
    color: unset;
    &-mobile {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: fit-content;
      padding: unset;
      * {
        text-align: start;
        margin: unset;
      }
      .user-info-photo {
        margin: 0px 10px 0px 0px;
        img {
          margin: unset;
          width: 100px;
          height: 100px;
          border: none;
        }
      }
    }
  }

  .user-info-value-mobile {
    color: $base_color;
  }

  .user-info-panel-mobile_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .user-info-name {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      font-weight: 800;
      text-align: unset;
    }
    .user-info-role {
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #41aea6;
      font-weight: 800;
    }
    .user-info-inProgram {
      display: flex;
      align-items: center;
      margin-top: unset;
      font-size: 14px;
      line-height: 21px;
      font-style: normal;
      span {
        margin: unset;
        margin-left: 3px;
        font-size: 14px;
        line-height: 21px;
        font-style: normal;
      }
    }
  }

  .user-info-edit-buttons-container-mobile {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0px 10px 0px;
    > div {
      margin: unset;
      padding: 7px;
      box-shadow: unset;
      text-align: center;
      border: 1px solid $base_color;
      border-radius: 42px;
      border-width: 1px;
      font-size: 14px;
      font-weight: 500;
      width: calc((100% - 10px) / 2);
      height: 39px;
      color: #41aea6;
    }
    > div.selected {
      background-color: $base_color;
      color: white;
    }
  }

  .user-info-edit-button-mobile {
    margin: 5px !important;
  }
}

@media screen and (min-width: 1300px) {
  .admin-userDetail-panelCont {
    max-width: 260px;
  }
}

@media screen and (min-width: 1500px) {
  .admin-userDetail-panelCont {
    max-width: 275px;
  }
}

@media screen and (min-width: 1800px) {
  .admin-userDetail-panelCont {
    max-width: 300px;
  }
}
