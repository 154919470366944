@use 'sass:list';
$colors: rgb(221, 0, 6), rgb(221, 0, 6), rgb(243, 81, 20), rgb(233, 217, 13), rgb(145, 209, 42), rgb(221, 0, 6),
  rgb(42, 148, 41), rgb(38, 34, 202), rgb(239, 169, 231), rgb(160, 126, 236), rgb(220, 35, 176), rgb(57, 163, 187);

.birthday-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 340px;
  height: 280px;
  background-color: white;
  color: black;
  z-index: 1000;

  border-radius: 12px;
  box-shadow: 2px 2px 25px gray;

  transform: translate(-50%, -50%);
}

.birthday-container_close-button {
  position: absolute;
  top: 4px;
  right: 0px;
  width: 38px;
  height: 42px;
}

.birthday-container_close-button::after {
  content: '\00d7';
  line-height: 42px;
  font-size: 42px;
  cursor: pointer;
  opacity: 0.7;
}

.balloon-container {
  display: inline-block;
  width: 58px;
  height: 210px;
  position: absolute;
  z-index: 998;
  overflow: hidden;
  left: -60px;
}

.balloon {
  width: 58px;
  height: 70px;
  background-color: nth($colors, 1);
  border-radius: 80%;
  position: absolute;
  box-shadow: inset -8px -6px 0 rgba(0, 0, 0, 0.2);
  z-index: 998;
  overflow: hidden;
}

.balloon-bottom {
  position: relative;
  top: 80px;
  font-size: 14px;
  left: calc(50% - 6.5px);
  color: nth($colors, 1);
  z-index: 998;
  overflow: hidden;
}

.balloon-line {
  transform: translateY(90px) translateX(2px) rotate(90deg);
}

@function negativeNumber($i) {
  $returnNum: $i;

  @if $i % 2==0 {
    $returnNum: $returnNum * (-1);
  }

  @return $returnNum;
}

@for $i from 1 through 20 {
  .balloon-container:nth-child(#{$i}) {
    animation: balloons_#{$i} 6s linear infinite;
    animation-delay: #{$i * 0.3}s;

    $j: $i;

    @if $i >10 {
      $j: round(calc($i/2));
    }

    .balloon {
      background-color: nth($colors, $j);
    }

    .balloon-bottom {
      color: nth($colors, $j);
    }
  }

  @keyframes balloons_#{$i} {
    0% {
      transform: translate(30 + random(50) + vw, 70vh) rotate(4deg);
      opacity: 0.2;
    }

    10% {
      opacity: 1;
    }

    50% {
      transform: translate(random(100) + vw, -30%) rotate(-12deg);
      opacity: 1;
    }

    100% {
      transform: translate(random(100) + vw, -120vh) rotate(12deg);
      opacity: 1;
    }
  }
}
