@import '../config';

#GoTopBtn {
  position: fixed;
  bottom: 26px;
  right: 49px;
  z-index: 1500;
  background-color: $base_color;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  * {
    position: relative;
    top: calc(50% - 15px); /* 50% - 3/4 of icon height */
  }
}

#GoTopBtn:hover {
  background-color: #555;
}

@media screen and (max-width: 768px) {
  #GoTopBtn {
    right: 26px;
  }
}
