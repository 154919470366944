.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  height: 40px;
  border: 1px solid #060606;
  border-radius: 25px;
  padding: 8px 16px;
}

.ant-input.search-input {
  border: none;
  padding: 0px;
  font-weight: normal;
  color: #060606;
  background: none;
}
.ant-input.search-input:hover {
  border: none;
  color: #060606;
}

.ant-input.search-input::placeholder {
  color: #a0a6a5;
  font-weight: normal;
}

.ant-input.search-input:focus {
  box-shadow: none;
}
