@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.register-background {
  background-color: $backgroundColor;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
}
.submenu-container {
  padding-top: 20px;
}
.register_client_background {
  background-color: $backgroundColor;
  margin: 80px auto 0 auto;
  width: 95vw;
  min-height: calc(100vh - 80px);
}

.register_client_header {
  text-align: center;
}

.register_client_header p {
  font-size: 18px;
}

.register_client_header h2 {
  font-weight: bold;
  padding: 20px;
  margin: 0;
}

.register_client_input {
  display: flex;
  justify-content: space-between;
}

.register_client_data {
  display: flex;
  justify-content: space-between;
}

.register-input-label {
  margin: 10px 0px 10px 0;
  font-size: 16px;
  color: #41aea6;
  font-weight: bold;
  text-align: center;
}

/*.register_client_right div.omamaDropdown {
  text-align: center;
  
}*/

.register_client_picture {
  height: 240px;
  width: 30%;
  text-align: inherit;
  position: relative;
  bottom: 46px;
  text-align: center;
}

.register_client {
  display: flex;
}
.register_client_right {
  width: 70%;
}

.register_client_right .omamaDropdown,
.textFormWrapper .omamaDropdown {
  display: flex;
  justify-content: center;
}

.omamaDropdown {
  .ant-dropdown-trigger {
    height: 40px;
    width: 263px;
    background-color: #41aea6;
    color: white;
    border-radius: 36px;
    text-align: center;
    line-height: 40px;
    font-size: 19px;
    margin: 0px !important;
    .anticon-down {
      position: relative;
      left: 30px;
      bottom: 4px;
    }
  }
}

#selectOmama {
  margin: 20px 0 30px;
}

.genderDropdown {
  .ant-dropdown-trigger {
    display: flex;
    justify-content: space-between;
    height: 38px;
    width: 180px;
    border: solid 2.5px #41aea6;
    background-color: white;
    color: gray;
    border-radius: 36px;
    text-align: left;
    padding: 0px 11px;
    line-height: 34px;
    font-size: 16px;
    font-weight: bold;
    .anticon-down {
      padding-top: 12px;
      color: #41aea6;
    }
  }
  .gender-placeholder {
    color: #dddddd;
  }
}

.ant-input.register_input {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
}

.register_client {
  .ant-calendar-picker-input {
    border-color: #41aea6;
    border-radius: 36px;
    border-width: 2.5px;
    color: gray;
    font-weight: bold;
    font-size: 16px;
    height: 38px;
  }
}

.register_client_input {
  .ant-calendar-picker-input {
    border-color: #41aea6;
    border-radius: 36px;
    border-width: 2.5px;
    color: gray;
    font-weight: bold;
    font-size: 16px;
    height: 38px;
  }
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.register_client_ui {
  border-bottom: 3px solid #41aea6;
  margin: 0 70px;
}

.register_client_ui.no-border {
  border-bottom: unset !important;
}

.register_client .register_client_picture .registerUpload {
  position: absolute;
  width: 100%;
  bottom: -145px;
}

.registerUpload span.ant-upload button {
  background: #41aea6;
  border-radius: 36px;
  color: white;
}

.register_client .register_client_picture canvas {
  width: 240px !important;
  height: 240px !important;
  position: relative;
  top: 46px;
}

.register_client_picture div div.icon-wrapper {
  position: absolute;
  width: 100% !important;
  bottom: -80px;
}

.register_client .register_client_picture div .icon-wrapper {
  justify-content: center;
}

#tehotenstvo {
  padding-bottom: 0;
}

.tehotenstvo_ui {
  text-align: left;
}

.yesNoQuestion {
  margin-bottom: 25px;
  margin-top: 25px;
  &-header {
    font-size: 18px;
  }
}

.headline-materskaSkola {
  font-size: 30px;
  color: #41aea6 !important;
  border-top: 3px solid #41aea6;
}

.yesNoQuestion > h2 {
  font-weight: bold;
  color: #9999a0;
}

.yesNoQuestion > button {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 35px;
  font-size: 1.2rem;
  background-color: white;
  color: #41aea6;
  font-weight: bold;
  margin-right: 20px;
}

.textFormWrapper {
  display: inline-block;
  width: 40%;
  margin-bottom: 25px;
  margin-right: 10px;
  margin-left: 5%;
}

.textFormWrapper > h2 {
  font-weight: bold;
  color: #9999a0;
}

.porod {
  display: inline-block;
  width: 100%;
}

.porod > h2 {
  font-weight: bold;
  color: #9999a0;
}

.porod > p {
  font-size: 18px;
}

.porod div.notSelected {
  border: 2px solid #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 40px;
  font-size: 1.2rem;
  background-color: white;
  color: #41aea6;
  font-weight: bold;
  margin-bottom: 25px;
  width: 220px;
  line-height: 36px;
  display: inline-flex;
  justify-content: center;
  margin-right: 30px;
}

.porod div.notSelected.selected {
  color: white;
  background-color: #41aea6;
  border-color: #41aea6;
}

.komplikacie h2 {
  font-weight: bold;
  color: #41aea6;
  margin-bottom: 0;
}

.komplikacie p {
  font-size: 18px;
  font-weight: bold;
  color: #9999a0;
}

.register_client_ui div textarea {
  background: rgba(231, 231, 231, 0.65);
  margin-bottom: 25px;
  box-shadow: 0px 9px 40px -19px rgba(0, 0, 0, 0.75);
}

.vyvin_text textarea {
  background: rgba(231, 231, 231, 0.65);
  margin-bottom: 10px;
  box-shadow: 0px 9px 40px -19px rgba(0, 0, 0, 0.75);
}

.register_client_input {
  .ant-calendar-picker-icon {
    z-index: 0;
    color: #41aea6;
  }
}

.register_client {
  .ant-calendar-picker-icon {
    z-index: 0;
    color: #41aea6;
  }
}

.vyvin {
  display: inline-flex;
  justify-content: flex-start;
  margin: 25px 70px;
}

.vyvin_text {
  margin: 25px 70px;
}

.vyvin_text h2 {
  font-size: 18px;
  font-weight: bold;
  color: #9999a0;
}
.atributy {
  width: 12.5%;
}

.atributy > h2 {
  font-weight: bold;
  font-size: 1.3em;
  color: #9999a0;
}

.komplikacie {
  font-weight: bold;
}

.atributy > input {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
  text-align: center;
}

.registration_footer {
  text-align: center;
}

.registration_footer > button {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 1.2rem;
  background-color: #41aea6;
  color: white;
  font-weight: bold;
  margin-left: 40px;
  margin-bottom: 25px;
  width: 120px;
  position: fixed;
  bottom: 1px;
  right: 110px;
}

.adding_surodenci {
  font-size: 16px;
  margin-bottom: 25px;
}

.adding_surodenci h3 {
  color: #41aea6;
  font-weight: bold;
}

.yesNoQuestion div.notSelected {
  border: 2px solid #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 45px;
  font-size: 1.2rem;
  background-color: white;
  color: #41aea6;
  font-weight: bold;
  width: 110px;
  line-height: 42px;
  display: inline-flex;
  justify-content: center;
  margin: 5px 15px;
}

.yesNoQuestion div.notSelected.selected {
  color: white;
  background-color: #41aea6;
  border-color: #41aea6;
}

.registerClientPhoto {
  img {
    height: 288px;
    border-radius: 50%;
  }
}

.growth {
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}

.growth-label {
  display: inline-block;
  width: 50%;
  color: #9999a0;
  font-weight: 700;
}

.growth-select {
  width: 140px;
}

.growth-select .ant-select-selection {
  border: 2px solid #41aea6;
  border-radius: 14px;
}

.activeStep,
.hiddenStep {
  display: block;
}

.mobileClientFormHeader {
  display: none;
}

.mobilePicture {
  display: none;
}

.languageMobile {
  display: none;
}

.yesNoAnswer {
  display: flex;
  justify-content: space-around;
}

.yesNoAnswerHealth {
  display: flex;
  justify-content: center;
}

.yesNoLabel {
  font-size: 20px;
}

.inputMobile,
.inputMobile-text,
.inputMobile-textarea {
  display: none;
}

.desktopOnly {
  display: inline-block;
}

.mobileOnly {
  display: none;
}

#examinationDate {
  margin-top: 30px;
}

.yesNoQuestionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.yesNoQuestionWrapper:last-of-type > .yesNoQuestion:last-of-type {
  padding-bottom: 40px;
}

.endOfStep {
  padding-bottom: unset;
}

#pregnancyMedicineHeader {
  padding-top: 40px;
  color: #9999a0;
}

@media screen and (max-width: 750px) {
  .register-background {
    background: white !important;
    position: unset;
  }
  .register_client_background {
    margin: 60px 0 0;
    width: 100%;
  }

  .registration_client_firstStep {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #41aea6;
    margin: 0 24px 20px;
    padding-top: 10px;
  }
  .registration_client_nextSteps {
    display: flex;
    flex-direction: row-reverse;
    color: #41aea6;
    margin: 0 24px 20px;
    padding-top: 10px;
  }
  .register_client_ui {
    border-bottom: none;
    margin: 0 24px;
  }
  .activeStep {
    display: block;
  }
  .hiddenStep {
    display: none;
  }
  .mainHeader {
    display: none;
  }
  .mobileClientFormHeader {
    display: block;
    margin: 0 0 20px;
  }
  .register_client_picture {
    display: none;
  }
  .register_client_right {
    width: 100%;
  }
  .register_client_data {
    display: flex;
    flex-direction: column;
  }
  .register-input-label {
    display: none;
  }
  .register-input-label > h3 {
    font-size: 14px !important;
    text-align: left;
  }

  .genderDropdown {
    .ant-dropdown-trigger {
      width: 100%;
      border-width: 1px;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .ant-input.register_input {
    margin-bottom: 10px;
    border-width: 1px;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    ::placeholder {
      color: #bdbdbd !important;
    }
  }
  .mobileInput {
    margin-bottom: 10px;
    border-width: 1px;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    ::placeholder {
      color: #bdbdbd;
    }
  }
  .register_datepicker {
    width: 100%;
  }
  #examinationDate {
    margin-top: 0px;
  }
  .register_client,
  .register_datepicker {
    .ant-input.ant-calendar-picker-input {
      margin-bottom: 10px;
      border-width: 1px;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      border-width: 1px !important;
      border-right-width: thin !important;
      ::placeholder {
        color: #bdbdbd;
      }
    }
  }
  .ant-calendar-picker-icon {
    top: 35%;
  }
  .ant-calendar-picker-clear {
    top: 35%;
  }

  .yesNoQuestion {
    flex-direction: column !important;
    margin: unset;
    width: unset !important;
  }

  .yesNoQuestion > div {
    width: 100% !important;
  }

  .yesNoQuestion > h2,
  .yesNoQuestion > h3 {
    padding: 0px;
    text-align: left;
    font-size: 14px !important;
    margin: 10px 0px;
  }

  .yesNoQuestion > .textFormWrapper {
    margin-top: 10px !important;
  }

  .yesNoLabel {
    font-weight: 500;
    font-size: 14px;
    color: #808080;
    margin: 10px 0;
  }
  .yesNoAnswer,
  .yesNoAnswerHealth {
    display: flex;
    justify-content: space-between;
  }

  .mobileLayout {
    flex-direction: column;
  }

  .mobileLayout > div {
    width: 100% !important;
  }

  .yesNoButton {
    width: 93px !important;
    height: 39px !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 39px !important;
    border-width: thin !important;
    padding: unset !important;
    margin: 10px 0 !important;
  }
  .omamaDropdown .ant-dropdown-trigger {
    font-size: 14px !important;
    margin: 20px 0 10px !important;
    width: 100% !important;
  }

  .mobilePicture {
    display: flex;
    flex-direction: column;
    align-items: center;
    .registerClientPhoto {
      img {
        height: 173px;
        border-radius: 50%;
      }
    }
  }

  .sectionHeader {
    text-align: left !important;
    font-size: 16px !important;
    padding: unset !important;
    margin-bottom: 10px !important;
    border-top: unset !important;
  }

  #psychomotorHeader {
    margin: 0 0 20px !important;
  }

  #psychomotorLabelSize {
    font-size: 12px;
  }

  .subsectionHeader {
    text-align: left;
    font-size: 16px !important;
    padding: 0px !important;
    font-weight: normal !important;
    margin-bottom: 15px !important;
    //color: #808080 !important;
  }

  .register_client_input {
    display: flex;
    flex-direction: column;
    &-mobileVersion {
      width: 100% !important;
    }
  }

  .register_client_ui div textarea {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    border-bottom: 1px solid #41aea6;
    margin-bottom: 15px !important;
    height: 40px;
    padding: 5px;
  }

  .vyvin_text textarea {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    border-bottom: 1px solid #41aea6;
    margin-bottom: 15px !important;
    height: 40px;
    padding: 5px;
  }

  .inputDesktop {
    display: none;
  }

  .inputMobile {
    display: block;

    &-text {
      display: block;
      margin: 10px 0;
      padding: 0;
      color: #808080;
      text-align: left;
      font-weight: normal;
      font-size: 14px;
    }

    &-textarea {
      display: block;
      background: unset;
      box-shadow: unset;
      border-radius: unset;
      border-bottom: 1px solid #41aea6;
      height: 40px;
      padding: 5px;
      margin: unset;
    }
  }

  .mobileLabel {
    margin: 10px 0;
    padding: 0;
    color: #808080 !important;
    text-align: left;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  .boldLabel {
    font-weight: bold !important;
  }

  .mobileTextareaHeadline {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #808080 !important;
    text-align: left;
    padding: 0px;
  }

  .tehotenstvo_ui {
    flex-direction: column !important;
    align-items: unset !important;
  }

  .tehotenstvo_ui > div {
    flex-direction: column !important;
    justify-content: unset !important;
  }

  .textFormWrapper {
    width: 100%;
    margin: 0px;
    &-noLabel {
      display: none;
    }
  }

  .textFormWrapper > h3 {
    font-size: 14px !important;
    margin-top: 0px !important;
  }

  .childbirthLabels {
    flex-direction: column;
    &-section {
      max-width: unset !important;
    }
    &-button {
      font-size: unset !important;
      border-width: 1px !important;
      margin: 0 0 10px !important;
      width: 100% !important;
      font-weight: normal !important;
    }
  }

  /*.komplikacie h2 {
    font-size: 14px;
    //font-weight: normal;
    //color: #9999a0;
    text-align: left;
    padding: 0;
    margin: 20px 0 10px;
  }
  
  .komplikacie p {
    font-size: 14px;
    font-weight: normal;
    color: #9999a0;
  }*/
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

  .growth {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px;
    &-select {
      width: 100%;
    }
    &-select .ant-select-selection {
      border: 1px solid #41aea6;
    }
  }

  .porod {
    margin-bottom: 15px !important;
  }

  .porod > h2 {
    margin-top: 15px;
  }

  .registration_footer {
    display: none;
  }

  .vyvin_text {
    margin: 0 24px;
  }

  .vyvin_text h2 {
    color: #808080;
  }

  .vyvin_text .yesNoQuestionWrapper:first-of-type {
    margin-top: 15px;
  }

  .yesNoQuestionWrapper {
    flex-direction: column;
  }

  .headline-materskaSkola.sectionHeader {
    font-size: 16px !important;
  }

  .headline-materskaSkola.sectionHeader + div {
    flex-direction: column;
  }

  .kindergarten {
    flex-direction: column !important;
    align-items: unset !important;
  }

  .kindergartenYears {
    width: 100% !important;
  }

  ._3Tv81 {
    margin-bottom: 30px;
    padding: 0 10px;
  }
  ._2ZdKn {
    height: unset;
    line-height: unset;
  }

  ._2NVM0 {
    top: 50%;
  }

  ._ZFVon {
    width: 11px;
    height: 11px;
    border-color: #c4c4c4 !important;
    background-color: #c4c4c4;
  }

  ._ZFVon._3BlFF {
    border-color: #41aea6 !important;
  }

  .endOfStep {
    padding-bottom: 20px;
  }

  .next_back_buttons {
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
  }

  .button-next {
    text-align: right;
    padding-right: 5px;
    color: #41aea6;
    font-weight: bold;
    position: relative;
    &-icon {
      position: absolute;
      top: 18px;
      padding-left: 5px;
    }
  }

  .button-back {
    color: #41aea6;
    font-weight: bold;
    position: relative;
    &-icon {
      position: absolute;
      top: 18px;
    }
    &-text {
      padding-left: 20px;
    }
  }

  .registration_button > button {
    border-color: #41aea6;
    box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
    border-radius: 24px;
    border-width: 2px;
    background-color: #41aea6;
    color: white;
    width: 93px;
    height: 39px;
    font-weight: normal;
    font-size: 14px;
    line-height: 39px;
    border-width: thin;
  }

  .mobileRegisterButton {
    display: flex !important;
    justify-content: center;
    padding-bottom: 60px;
  }

  .registerUpload {
    padding-top: 20px;
  }
}
