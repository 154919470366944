@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.stageItem .ant-descriptions-item-label {
  color: $base_color;
  font-weight: bold;
}

.stageItem .ant-descriptions-item-content {
  color: black;
  display: block;
  font-size: initial;
  padding-top: 8px;
}

.stageTitle .ant-descriptions-item-content {
  color: $base_color;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Montserrat', arial;
  width: 100%;
  padding-bottom: 24px;
}

.ant-descriptions-row > td.stageTitle {
  margin-bottom: -3vh;
}

.ant-descriptions-row > td.stageItem {
  display: block;
}

.ant-descriptions-row > td.displayItem {
  display: block;
}

.descriptions.ant-descriptions {
  margin: auto;
  width: 100%;
}

.developmentStageInfo-main {
  overflow: auto;
  //height: calc(100vh - 70px);
}

.developmentStageInfo-main .upload-photo-container {
  margin-top: 30px;
}

.stagePhoto {
  margin-bottom: 5px;
  height: auto;
  width: 100%;
}

.editStageFormField {
  max-width: 600px;

  div {
    width: 100%;

    &.row {
      display: flex;
      justify-content: space-between;
    }
  }

  textarea {
    margin-bottom: 25px;
    box-shadow: 0px 9px 40px -19px rgba(0, 0, 0, 0.75);
    color: rgb(128, 128, 128);
    font-family: Montserrat, arial;
    border-color: $base_color;
    border-width: 2.5px;
    border-radius: 13px;
  }
}

.add-input {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
}

.editStageAddPhotoFormField {
  margin-bottom: 15px;

  div {
    width: 100%;
  }
  i {
    font-size: 25px;
    margin: auto;
    margin-left: 10px;
    padding-bottom: 3px;
    cursor: pointer;
  }
  .stagePhotoItem,
  .stageYoutubeItem {
    display: flex;
  }
}

.uploadedImage {
  img {
    max-width: 300px;
    margin-bottom: 8px;
  }
}

.ant-btn.stageButton {
  background-color: #41aea6;
  color: white;
  padding: 0 16px;
  border-radius: 29px;
  font-size: 19px;
}

.urlErrorMessage {
  color: red;
}

@media screen and (max-width: 768px) {
  .uploadedImage {
    img {
      max-width: 200px;
    }
  }
}
