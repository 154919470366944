@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../_config.scss';

.gallery {
  text-align: center;
  height: 100vh;
  position: absolute;
}

.galleryActionFolderName {
  margin: 0 20px;
}

.galleryCard {
  width: 42vw;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  margin: 10px;
  opacity: 0.9;
  cursor: pointer;
}

.galleryContainer {
  min-height: 100svh;
  background-color: white;
}

.galleryCardWrapper {
  display: flex;
  text-align: center;
  background-color: white;
  padding-bottom: 100px;
  padding-top: 80px;
  flex-wrap: wrap;
  justify-content: center;
}

.galleryCardImage {
  img {
    border-radius: 50%;
    height: 70px;
    display: block;
  }
}

.galleryCardImageName {
  font-size: 17px;
  margin: 21px 25px 0 25px;
  padding-top: 5px;
  font-weight: 600;
  color: $base_color;
  text-align: center;
}

@media screen and (max-width: 400px) {
  .galleryCard {
    margin: 10px;
    opacity: 0.9;
    height: 150px;
  }
  .galleryCardImage {
    img {
      height: 60px;
    }
  }

  .galleryCardImageName {
    font-size: 15px;
    margin: 10px 25px 0 25px;
  }
}
