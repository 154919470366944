@import '../config';

.editProfile-statusButtons {
  display: flex;
  justify-content: center;
  & > div {
    margin: 0 10px;
    border: 2px solid $base_color;
    text-align: center;
    box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
    border-radius: 24px;
    border-width: 2px;
    height: 35px;
    font-size: 1.2rem;
    background-color: white;
    color: #41aea6;
    font-weight: bold;
    width: 130px;
    cursor: pointer;
  }
  & > div.selected {
    background-color: $base_color;
    color: white;
  }
}

.hideRespo {
  display: block;
}

.editProfile-toolsCont {
  display: block;
  &-mobile {
    display: none;
  }
}

.editProfile-addToolCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > button {
    margin: 10px 0px 15px;
  }
}

.editProfile-mentorMobile {
  display: none;
}

.editProfile-container {
  background-color: white;
  border-radius: 25px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.ant-input.editProfile-input {
  width: 50vw;
  margin: 5px 0;
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
}

.ant-input.editProfile-input.errorInput {
  border-color: red;
}

.editProfile-cityWrapper {
  display: inline-block;
  position: relative;
  width: 50vw;
}

input.editProfile-autocomplete {
  width: 100%;
  margin: 5px 0;
  color: gray;
}

input.editProfile-autocomplete::placeholder {
  color: #bfbfbf;
}

.editProfile-label {
  text-align: center;
  color: $base_color;
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;

  .anticon {
    vertical-align: 0;
  }
}

.editProfile-saveButton {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  & > button {
    margin: 0 10px;
    border: 2px solid $base_color;
    text-align: center;
    box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
    border-radius: 24px;
    border-width: 2px;
    height: 35px;
    font-size: 1.2rem;
    background-color: $base_color;
    color: white;
    font-weight: bold;
  }
  & > button.ant-btn:hover,
  button.ant-btn:focus {
    color: $base_color;
    border-color: $base_color;
  }
}

.editProfile-dropdown {
  margin: 20px 0;
  &-chooseMentor {
    margin: unset;
    margin: 20px 0px 15px;
  }
  .ant-dropdown-trigger {
    max-width: 230px;
    border: 2px solid $base_color;
    color: white;
    background-color: $base_color;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
    border-radius: 32px;
    cursor: pointer;
    i {
      position: relative;
      top: -3px;
      right: -5px;
    }
  }
}

.editProfile-header {
  display: none;
}

.editProfile-datePicker {
  width: 230px;
  margin: 0 auto;
  i {
    top: 45%;
    color: $base_color;
    cursor: pointer;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-calendar-picker-input {
    text-align: center;
  }
}

.editProfile-showErrors {
  color: red;
}

.errorInput {
  border-color: red;
}

.editProfile-desktop {
  display: block;
}

@media screen and (max-width: 768px) {
  .statsBackground {
    background: unset;
    background-color: $backgroundColorMobile;
    .adminBox {
      width: 100%;
    }
    .admin-main {
      background-color: $backgroundColorMobile;
      padding: 0px 20px;
      margin: 50px 0px;
      width: 100%;
    }
  }

  .editProfile-closeButton {
    display: none;
  }

  .editProfile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 20px;
    h2 {
      padding: 0px;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
    }
    .back-button {
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
    }
    h3 {
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  .editProfile-toolsCont {
    display: none;
    &-mobile {
      margin-top: 20px;
      margin-bottom: 40px;
      display: block;
      &-inputCont {
        position: relative;
        &-icon {
          position: absolute;
          right: 13px;
          top: 9px;
          width: 21px;
          height: 21px;
        }
      }
      p {
        margin-top: 10px;
        text-align: center;
      }
      ul {
        margin-top: 10px;
        padding: 0px 10px 0px 10px !important;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $base_color;
          padding: 10px 0px;
          color: #4f4f4f;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }

  .editProfile-mentorMobile {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    .editProfile-dropdown {
      margin: 0px;
      .ant-dropdown-trigger {
        max-width: 100%;
        height: 39px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: unset;
        padding: 0px 21px;
        i {
          top: unset;
          right: unset;
        }
      }
    }
  }

  .editProfile-label {
    display: none;
    &.mobileLabel {
      display: flex;
      font-weight: 800;
      margin-top: unset;
      margin-bottom: 10px;
      color: $base_color;
    }
  }

  .ant-input.editProfile-input {
    width: 100%;
    margin: unset;
    height: 39px;
    border-width: 1px;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 21px;
    color: #4f4f4f;
    padding: 12px;
  }

  .editProfile-statusButtons {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    > div {
      margin: unset;
      padding: 7px;
      box-shadow: unset;
      border: 1px solid $base_color;
      border-radius: 42px;
      border-width: 1px;
      font-size: 14px;
      font-weight: 500;
      width: calc((100% - 10px) / 2);
      height: 39px;
    }
  }

  .editProfile-saveButton {
    margin-top: 45px;
    padding-bottom: 50px;
    > button {
      box-shadow: unset;
      border-radius: 24px;
      width: 150px;
      height: 39px;
      font-size: 14px;
      font-weight: normal;
      vertical-align: text-bottom;
    }
  }

  .editProfile-datePicker {
    max-width: 230px;
    margin: 0 auto;
    .register_datepicker .ant-input.ant-calendar-picker-input {
      margin-bottom: unset;
    }
  }

  .editProfile-showErrors {
    margin-top: 9px;
  }

  .hideRespo {
    display: none;
  }

  .editProfile-cityWrapper {
    width: 100%;
  }

  .editProfile-desktop {
    display: none;
  }

  input.editProfile-autocomplete {
    margin: 0;
    color: #4f4f4f;
  }
}
