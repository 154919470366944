@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.showJustOnMobile {
  display: none;
}

.adminBox {
  background-color: $backgroundColor;
  margin: 0 auto;
  width: 95vw;
  min-height: calc(100vh - 80px);
}

.admin-mentor-panel {
  width: 200px;
  height: 400px;
  background-color: white;
  padding: 15px 12%;
  border-radius: 25px;
  * {
    text-align: center;
    margin: 0 auto;
  }
  .admin-mentor-photo img {
    border-radius: 50%;
    display: block;
    width: 50%;
    height: 50%;
    margin: 10px auto;
  }

  .admin-mentor-name {
    font-size: 1rem;
  }
  .admin-mentor-region {
    margin: 3px 0;
    font-weight: 100;
  }
  .admin-mentor-city {
    margin: 3px 0;
    font-weight: 100;
  }

  .admin-mentor-date {
    margin-top: 4px;
    margin-bottom: 12px;
  }

  .admin-mentor-name,
  .admin-mentor-date {
    display: block;
    color: $base_color;
  }
  &-mobile {
    display: none;
  }
}

.admin-mentor-editprofile {
  width: 95%;
}

.admin-mentor-editprofileBtn {
  text-align: center;
  margin-top: 20px;
  display: block;
}

.ant-btn:hover.admin-mentor-editprofile,
.ant-btn:focus.admin-mentor-editprofile {
  color: $base_color;
  border-color: $base_color;
}

// RIGHT panel

.admin-generalUserInfo {
  flex: 1;
  .ant-descriptions {
    margin-top: 15px;
  }

  h2 {
    margin-top: 20px;
    font-size: 16px;
    color: #41aea6;
  }
}

.mentorReportAdmin-main {
  padding: 0 10px 0;
  * {
    text-align: center;
  }
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userMobileList-item {
  display: none;
}

@media screen and (max-width: 768px) {
  .admin-generalUserInfo {
    display: none;
  }

  .admin-userDetail {
    flex-direction: column;
    &-panelCont {
      width: 100%;
    }
  }

  .admin-mentor-panel {
    display: none;
    &-mobile {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: fit-content;
      padding: unset;
      * {
        text-align: start;
        margin: unset;
      }
      .admin-mentor-photo {
        margin: 0px 10px 0px 0px;
        img {
          margin: unset;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .admin-generalUserInfoMobile {
    margin-top: 20px;
    display: flex;
    padding-left: 20px;
    &_row {
      margin-bottom: 17px;
      img {
        margin-right: 20px;
      }
      span {
        font-size: 16px;
        line-height: 21px;
        color: #333333;
      }
    }
    &_row:last-of-type {
      margin-bottom: 0px;
    }
  }

  .admin-mentor-panel-mobile_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .admin-mentor-name {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      font-weight: 800;
    }
    .admin-mentor-role {
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #41aea6;
      font-weight: 800;
    }
    .admin-mentor-inProgram {
      display: flex;
      align-items: center;
      margin-top: unset;
      font-size: 14px;
      line-height: 21px;
      font-style: normal;
      span {
        margin: unset;
        margin-left: 3px;
        font-size: 14px;
        line-height: 21px;
        font-style: normal;
      }
    }
    .admin-mentor-status {
      color: $base_color;
    }
  }

  .admin-mentor-tabsMobile {
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
    &_row {
      border-bottom: 1px solid $base_color;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        margin-right: 17px;
        color: $base_color;
      }
      span {
        padding-left: 10px;
        font-weight: 800;
        font-size: 20px;
        line-height: 21px;
        color: $base_color;
      }
    }
    &_row:last-of-type {
      margin-bottom: 0px;
    }
  }

  .mentorReportAdmin-main {
    height: calc(100vh - 50px - 40px);
    overflow-y: scroll;
  }

  .tab_modal {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    padding-top: 50px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    margin: 0px;
    padding-bottom: 800px;
    background-color: white;
    z-index: 999;
    &_menu {
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
      &-closeIcon {
        margin-top: 0px !important;
        margin-right: 0px !important;
      }
    }
    .admin-mentor-bottompanel {
      .container-inner {
        padding-top: 0px;
        .container-inner-options-monthPicker {
          width: 33%;
          margin: 0px 5px;
        }
        .container-inner-options-rangePicker {
          width: 50%;
          height: 38px;
        }
        .container-inner-options-addAction {
          width: unset;
        }
        .ant-calendar-picker {
          transform: unset !important;
        }
        .container-inner-toDoList {
          margin: unset;
          margin-top: 20px;
          padding: 0px 5px;
          height: calc(100vh - 120px - 40px);
          overflow-y: scroll;
        }
      }
    }
  }

  .omamasMobileList {
    width: 100%;
    margin-top: 20px;
    height: calc(100vh - 70px - 40px);
    overflow-y: scroll;
    padding: unset;
  }

  .userMobileList-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $base_color;
    &-leftCont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-tablePhoto {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        img {
          height: 50px;
          border-radius: 50%;
        }
      }
      &-userInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        &-name {
          color: #333333;
          font-weight: normal;
          padding-bottom: 2px;
          margin: 0px 0px;
        }
        &-city {
          color: #808080;
          font-style: normal;
          margin: 0px 0px;
        }
      }
    }
    &-rightCont {
      display: flex;
      align-items: center;
      &-role {
        color: $base_color;
        font-size: 12px;
        line-height: 21px;
        font-weight: bold;
        padding-right: 7px;
        margin: 0px 0px;
      }
      &-icon {
        color: $base_color;
      }
    }
  }

  .doNotShowOnMobile {
    display: none;
  }
  .showJustOnMobile {
    display: block;
  }
}
