.mentor-report-container {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: white;
    .mentor-report {
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        padding: 40px;
    }
}

@media screen and (min-width: 768px) {
    .mentor-report-container {
        padding-top: 80px;
    }
}
  