@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.addNewactivityFormField {
  max-width: 600px;

  h3 {
    margin-right: 20px;
    margin-top: 3px;
    width: 150px;
  }
  div {
    width: 464px;

    &.row {
      display: flex;
      justify-content: space-between;
    }
  }

  input {
    font-family: Montserrat, arial;
  }

  textarea {
    margin-bottom: 25px;
    box-shadow: 0px 9px 40px -19px rgba(0, 0, 0, 0.75);
    color: rgb(128, 128, 128);
    font-family: Montserrat, arial;
    border-color: $base_color;
    border-width: 2.5px;
    border-radius: 13px;
  }
}

.markdownHelp {
  h4 {
    color: #41aea6;
  }
  .section {
    margin-top: 10px;
    display: flex;
    .column {
      margin-top: 0;

      &:first-child {
        width: 130px;
      }

      &.noMargin p {
        margin-bottom: 0;
      }
    }
  }

  .whitespaced {
    white-space: pre-wrap;
  }
}

.aktivityGoBackNav {
  display: flex;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: center;
  i {
    padding-top: 6px;
    font-size: 20px;
  }
  div {
    margin-left: 10px;
    color: #41aea6;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
}

.addNewactivityPhotosFormField {
  margin-bottom: 15px;

  div {
    width: 464px;
  }
  i {
    font-size: 25px;
    margin-left: 10px;
    padding-top: 3px;
    cursor: pointer;
  }
  .activityPhotoItem,
  .activityYoutubeItem {
    display: flex;
  }
}

.addNewactivityFormContainer {
  background-color: $backgroundColor;
  margin: 80px auto 0 auto;
  width: 95vw;
  min-height: calc(100vh - 80px);
  padding: 25px 70px;
}

.register-background {
  background-color: $backgroundColor;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
}

.add-input {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
}

.markdownPreview {
  h2,
  h3 {
    color: initial;
    font-weight: initial;
    padding: initial;
    margin: initial;
  }

  &:not(.aligned) {
    h2,
    h3 {
      text-align: initial;
    }
  }
}

h2 {
  font-weight: bold;
  padding: 20px;
  margin: 0;
  text-align: center;
}

.omamaDropdown {
  .ant-dropdown-trigger {
    height: 40px;
    width: 263px;
    background-color: #41aea6;
    color: white;
    border-radius: 36px;
    text-align: center;
    line-height: 40px;
    font-size: 19px;
    margin: 30px 0 50px 0;
  }

  .ant-dropdown-trigger {
    .anticon-down {
      position: relative;
      left: 30px;
      bottom: 4px;
    }
  }
}

.leftSection {
  max-width: 600px;
  float: left;
}

.rightSection {
  max-width: 600px;
}

.formSection {
  display: flex;
  justify-content: space-evenly;
}

.regButton {
  margin: 0px;
  text-align: center;
  button {
    border-color: #41aea6;
    box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
    border-radius: 24px;
    border-width: 2px;
    height: 50px;
    font-size: 1.2rem;
    background-color: #41aea6;
    color: white;
    font-weight: bold;
    width: 243px;
    margin: 0px;
  }
}

.uploadedImage {
  img {
    max-width: 123px;
    margin-bottom: 8px;
  }
}

.activityNameText {
  display: block;
  color: #060606;
  font-weight: bold;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .addNewactivityFormContainer {
    background-color: $backgroundColorMobile;
    margin: 50px auto 0 auto;
    width: 100%;
    min-height: calc(100vh - 80px);
    padding: 20px 20px;
  }
  .formSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100%;
    justify-content: unset;
  }
  .leftSection {
    width: 100%;
    float: unset;
  }
  .rightSection {
    max-width: 100%;
  }
  .addNewactivityFormField {
    width: unset;
    div {
      width: unset;
    }
  }
  .addNewactivityPhotosFormField {
    div {
      width: unset;
    }
  }
}
