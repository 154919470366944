@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.showJustOnMobile {
  display: none;
}

.adminBox {
  background-color: $backgroundColor;
  margin: 0 auto;
  width: 95vw;
  min-height: calc(100vh - 80px);
}

.admin-admin-editprofile {
  width: 95%;
}

.admin-admin-editprofileBtn,
.admin-admin-deleteProfileBtn {
  text-align: center;
  margin-top: 20px;
  display: block;
}

// RIGHT panel

.admin-generalUserInfo {
  margin-left: 25px;
  width: 100%;

  .ant-descriptions {
    margin-top: 15px;
  }

  h2 {
    margin-top: 20px;
    font-size: 16px;
    color: #41aea6;
  }
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .admin-userDetail {
    flex-direction: column;
    &-panelCont {
      width: 100%;
    }
  }

  .admin-generalUserInfoMobile {
    margin-top: 20px;
    display: flex;
    padding-left: 20px;
    &_row {
      margin-bottom: 17px;
      img {
        margin-right: 20px;
      }
      span {
        font-size: 16px;
        line-height: 21px;
        color: #333333;
      }
    }
    &_row:last-of-type {
      margin-bottom: 0px;
    }
  }

  .admin-editButtons {
    display: flex !important;
    justify-content: space-between;
    margin: 30px 0px 10px 0px;
    > div {
      margin: unset;
      padding: 7px;
      box-shadow: unset;
      text-align: center;
      border: 1px solid $base_color;
      border-radius: 42px;
      border-width: 1px;
      font-size: 14px;
      font-weight: 500;
      width: calc((100% - 10px) / 2);
      height: 39px;
      color: #41aea6;
    }
    > div.selected {
      background-color: $base_color;
      color: white;
    }
  }

  .admin-admin-panel-mobile_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .admin-admin-name {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      font-weight: 800;
    }
    .admin-admin-role {
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #41aea6;
      font-weight: 800;
    }
    .admin-admin-inProgram {
      display: flex;
      align-items: center;
      margin-top: unset;
      font-size: 14px;
      line-height: 21px;
      font-style: normal;
      span {
        margin: unset;
        margin-left: 3px;
        font-size: 14px;
        line-height: 21px;
        font-style: normal;
      }
    }
  }

  .doNotShowOnMobile {
    display: none;
  }
  .showJustOnMobile {
    display: block;

    .admin-supervisor-botoompanel {
      padding: 0px 10px 20px 10px;
    }
  }
}
