@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.item {
  border-bottom: 1px solid $base_color;
  margin: 0 15px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;

  .datum {
    width: 100px;
  }

  .time {
    width: 50px;
    color: $base_color;
  }

  .supervisor {
    width: 100px;
  }

  .points {
    color: $base_color;
    width: 50px;
    font-weight: bold;
  }

  .icon {
    position: relative;
    top: -2px;
    color: $base_color;
    width: 20px;
  }
}

@media screen and (max-width: 380px) {
  .item {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .item {
    font-size: 10px;
  }
}
