@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.user-register-background {
  background-color: $backgroundColor;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
}

.addNewactivityFormContainer {
  background-color: $backgroundColor;
  margin: 80px auto 0 auto;
  width: 95vw;
  min-height: calc(100vh - 80px);
  padding: 25px 70px;
  @media screen and (max-width: 768px) {
    background-color: $backgroundColorMobile;
  }
}

.registerForm,
.passwordsForm {
  .ant-input {
    border-color: #41aea6;
    border-radius: 36px;
    border-width: 2.5px;
    color: gray;
    font-weight: bold;
    font-size: 16px;
    height: 38px;
    padding-left: 19px;
  }

  .mobileInput {
    padding-left: 19px;
  }

  .mobileInput::placeholder {
    color: #bfbfbf;
  }

  div {
    width: 464px;
    margin-bottom: 25px;
    display: flex;
  }

  .selectRegion {
    align-items: center;
    :nth-child(2) {
      margin-left: 5px;
    }
  }

  .registerUserErrorMsg {
    margin-top: -25px;
    margin-bottom: 4px;
    padding-left: 20px;
  }

  .showErrors {
    margin-bottom: 0px;
    color: red;
  }

  .citySelector {
    .suggestions-container {
      margin-top: 40px;
    }

    .suggestions {
      width: 100%;
      max-height: 180px;
    }

    .no-suggestions {
      margin-bottom: 0px;
      margin-top: 8px;
      color: red;
      padding-left: 10px;
    }
  }
}

.roleDropdown {
  display: flex;
  text-align: left;
  &-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-left: 10px;
    }
  }

  .ant-dropdown-trigger {
    height: 40px;
    width: 100%;
    background-color: #41aea6;
    color: white;
    border-radius: 36px;
    text-align: center;
    line-height: 40px;
    font-size: 19px;
  }
}

.register_client_header {
  text-align: center;
  h2 {
    font-weight: bold;
    margin: 0;
  }
}

.forms {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.regButton {
  margin: 0px;
  text-align: center;
  button {
    border-color: #41aea6;
    box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
    border-radius: 24px;
    border-width: 2px;
    height: 50px;
    font-size: 1.2rem;
    background-color: #41aea6;
    color: white;
    font-weight: bold;
    width: 203px;
    margin: 0px;
  }
}

@media screen and (max-width: 768px) {
  .forms {
    margin-top: 20px;
    padding: unset;
    flex-direction: column;
    .registerForm,
    .passwordsForm {
      width: 100%;
      div {
        width: 100%;
        margin-bottom: 7px;
      }
      .registerUserErrorMsg {
        margin-top: -5px;
        margin-bottom: 5px;
        //padding-left: 20px;
      }
      .mobileInput {
        margin-bottom: 0px;
      }
      .citySelector {
        .no-suggestions {
          margin-bottom: 0px;
          margin-top: 0px;
          color: red;
          padding-left: 0px;
        }
      }
    }
    .passwordsForm {
      .roleDropdown {
        margin: 0px 0px 15px 0px;
        .actionsDropdown {
          margin-top: unset;
          margin-bottom: 0px;
          display: flex;
          width: 100%;
          height: 39px;
        }
        &-placeholder {
          justify-content: space-between;
          padding: 12px;
          padding-left: 19px;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .ant-input {
      border: 1px solid #41aea6;
      border-radius: 36px;
      color: #4f4f4f;
      width: 100%;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 21px;
      height: 39px;
      &::placeholder {
        color: #bdbdbd;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }
    }
  }

  .user-register-background {
    .addNewactivityFormContainer {
      padding: 0px 20px;
      margin: 50px 0px;
      width: 100%;
      .register_client_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        h2 {
          padding: 0px;
          font-weight: normal;
          font-size: 12px;
          line-height: 21px;
        }
        .back-button {
          font-weight: normal;
          font-size: 12px;
          line-height: 21px;
        }
        h3 {
          margin-top: unset;
          margin-bottom: unset;
        }
      }
      .regButton {
        button {
          margin-top: 10px;
          height: 39px;
          width: 150px;
          font-weight: normal;
          font-size: 14px;
          line-height: 29px;
        }
      }
    }
  }
  .ant-dropdown-menu-item {
    padding-left: 19px;
  }

  .citySelector {
    .no-suggestions {
      margin-bottom: 0px;
      margin-top: 0px;
      color: red;
      padding-left: 0px;
    }
  }
}
