@import '../_config.scss';

.imageGalleryMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0 10px;
}

#modalGallery {
  padding-bottom: 15px;
}

#modalGallery img {
  width: 100%;
  position: relative;
  padding: 60px 20px 5px;
}

#modalGallery button {
  margin-top: 10px;
  background-color: $base_color;
  border-color: $base_color;
}

#modalGallery button:hover {
  border-color: #2b8c84;
  background-color: #2b8c84;
}

.imageWrapper {
  position: relative;
  padding-bottom: 10px;
  width: 150px;
  text-align: center;
}

.imageWrapper > img {
  width: 90%;
  height: auto;
  cursor: pointer;
}

.modalGallery {
  .ant-modal-body {
    padding: 0px;
  }
}
