@import "../../../config";

#omama-profile {
	.section-title {
		color: $base_color;
		font-weight: bold;
		font-size: 16px;
	}

	.omama-level {
		.section-title:first-child {
			font-weight: normal;
			margin: 0;
		}
		.omama-position {
			font-size: 24px;
		}
	}	
}


#omama-profile-activity {
	margin-top: 20px;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 2px solid $base_color;
}

#omama-profile-milestones {
	margin-top: 20px;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 2px solid $base_color;

	.level-text {
		color: $base_color;
		margin: 20px 0;
	}
}
