@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.container-inner {
  &-info {
    &-showScreeningForm {
      text-align: right;
      color: $base_color;

      .anticon.anticon-right {
        padding-left: 5px;
        position: relative;
        top: -1px;
      }
    }
  }

  &-info-comment {
    font-size: 14px;
    margin: 0 10px;

    &-title {
      color: $base_color;
      padding-top: 20px;
    }
  }

  &-deleteButton {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}

.changeCommentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.changeCommentInput {
  width: 100%;
  max-width: 400px;
  min-width: 200px;
}
