@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.clientsBackground {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: $backgroundColor;
}

.clientsBox {
  background-color: $backgroundColor;
  margin: 0 auto;
  width: 95vw;
  //min-height: calc(100vh - 80px);
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    background-color: $backgroundColorMobile;
  }
}

.admin-main {
  padding: 40px;
  padding-top: 105px;
  min-height: 100vh;
  // width: calc(95vw - 70px);
}

.clientForm {
  padding-top: 8vh;
}

.ant-input.clientInput {
  font-weight: bold;
}

.autoClientInput .ant-select-selection {
  border-radius: 36px;
  border: none;
}

.autoClientInput .ant-input.ant-select-search__field {
  border-color: #41aea6;
  border-radius: 36px;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-width: 2px;
  height: 9vh;
  color: gray;
  font-weight: bold;
  font-size: 1.5rem;
}

.autoClientInput .ant-input.ant-select-search__field:focus,
.autoClientInput .ant-input.ant-select-search__field:hover {
  border-color: #41aea6;
}

.autoClientInput .ant-select-selection .ant-select-selection__rendered,
.autoClientInput .ant-select-selection__rendered {
  height: 7vh;
}

.autoClientInput .ant-select-selection__placeholder {
  font-weight: bold;
  color: #dddddd;
  font-size: 1.5rem;
}

.ant-btn.clientButton {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 36px;
  border-width: 2px;
  height: 7vh;
  font-size: 1.5rem;
  color: gray;
}

.ant-input.clientInput,
.autoClientInput .ant-input.ant-select-search__field {
  border-color: #41aea6;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 36px;
  border-width: 2px;
  height: 7vh;
  font-size: 1.5rem;
  padding-left: 20px;
  color: gray;
}

.ant-btn.clientButton {
  background-color: #41aea6;
  color: white;
  padding: 0 2vw;
  float: right;
}

/* zoznam pouzivatelov, klientov, userov */
.adminClientsList {
  display: flex;
  flex-flow: row wrap;
  margin-top: 50px;

  .AdminPersonCard {
    width: 30%;
  }
}

.ant-select-selection__placeholder {
  padding-left: 10px;
}

.clientsOmamaDropdown .ant-dropdown-trigger {
  height: 40px;
  width: 200px;
  background-color: #41aea6;
  color: white;
  border-radius: 36px;
  text-align: center;
  line-height: 40px;
  font-size: 19px;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: #41aea6;
}

.ant-table-small {
  border-radius: 40px;
}

.tableMobile tr th {
  display: none;
}

.tableMobile tr td {
  border-bottom: none;
}

.clientsOmamaDropdown {
  & > .ant-dropdown-trigger {
    & > i.anticon.anticon-down {
      position: relative;
      top: -3px;
    }
  }
}

.addClientIcon {
  display: none;
}

.clientsMobileView {
  display: none;
}

.clientsMobileView .personCard {
  margin-bottom: 0px;
  width: 95%;
}

.clientsDesktopView {
  .ant-table-thead > tr > th:nth-child(1) {
    padding-right: 0;
    width: 66px;
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    padding-right: 0;
  }

  .ant-table-thead {
    height: 68px;
  }

  .ant-table-tbody > tr {
    height: 68px;
  }
}

.clients-table-controls-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 40px;
}

.clients-table-controls-group {
  display: flex;
  gap: 32px;
}

.clients-search-bar {
  width: 250px;
}

.clients-table-dropdown {
  width: 250px;
}

.clients-table-control-button {
  font-size: 16px;
  width: 125px;
  margin-left: auto;
}

@media screen and (max-width: 750px) {
  .mobileBox {
    width: 100%;
    background-color: white;
  }

  .mobileActivities {
    padding: 64px 5px 64px !important;
    min-height: 100vh !important;

    &-form {
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
      margin-bottom: 0px;

      &-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .activities-form.mobileActivities-form {
    margin-bottom: unset;
    &-inputs {
      margin-bottom: 10px;
    }
  }

  .ant-input.activities-input.mobile {
    padding-left: unset;
    border: unset;
    border-bottom: 1px solid $base_color;
    border-radius: 0px;
    box-shadow: unset;
    font-size: 14px !important;
    font-weight: unset;
    color: unset;
    font-weight: normal;
    color: $base_color;
    width: 42.5%;

    &::placeholder {
      color: $base_color;
    }
  }

  .addClientIcon {
    display: unset;
    width: 15%;
  }
  .clientsOmamaDropdown .ant-dropdown-trigger {
    background-color: white;
    color: $base_color;
    width: unset;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .ant-btn.admin-searchButton {
    display: none;
  }

  .clientsDesktopView {
    display: none;
  }

  .clientsMobileView {
    display: block;
    padding-bottom: 10px;
  }
}

.activities-form.only-mobile-flex {
  display: none;
}

@media screen and (max-width: 768px) {
  .clients-table-controls-group-container.only-desktop {
    display: none;
  }

  .activities-form.only-mobile-flex {
    display: flex;
  }
}

@media screen and (max-width: 1500px) {
  .clients-search-bar {
    width: 225px;
  }

  .clients-table-dropdown {
    width: 225px;
  }
}

@media screen and (max-width: 1320px) {
  .clients-search-bar {
    width: 200px;
  }

  .clients-table-dropdown {
    width: 200px;
  }
}

@media screen and (max-width: 1200px) {
  .clients-table-controls-group-container {
    gap: 16px;
  }

  .clients-table-controls-group {
    gap: 16px;
  }
}
