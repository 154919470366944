@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.clientsActivities2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 805;
  background-color: white;
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', arial;
  font-weight: bold;
  overflow: scroll;
}

.photoIcon {
  height: 5vh;
  display: block;
  margin: 0 auto;
}

.mainClient {
  padding: 0 20px;
  //height: calc(100% - 200px);
}

.commentSection,
.addphotoSection {
  border-top: 1px solid $base_color;
  margin-top: 15px;
}

// Hidden scrollbar
.mainClient::-webkit-scrollbar {
  width: 0;
}

.photoButton div {
  color: $base_color;
}

.disabledPhotoButton {
  color: $base_color;
  opacity: 0.4;
  pointer-events: none;
}

.photoButtons {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.clientEndImg {
  width: 26vw;
  height: auto;
  margin: 5px;
}

.tempPhotos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  div,
  img {
    display: block;
  }

  .photo-container {
    position: relative;
  }
}

.activityRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.activityNameEnd {
  font-size: 1em;
  color: $base_color;
  width: 80vw;
  text-align: left;
}

.charCount {
  float: right;
  display: inline;
  position: relative;
  bottom: 30px;
  padding-right: 10px;
}

textarea {
  padding: 10px;
  height: 110px;
  width: 100%;
  background-color: lightgray;
  border-radius: 10px;
  border: 0px solid lightgray;
  font-family: 'Montserrat', arial;
  outline: none;
}

.hodnotenieNumber {
  text-align: center;
  font-size: 1em;
  height: 15px;
  color: $base_color;
}

.clientend-question {
  text-align: center;
  font-size: 1em;
  margin: 5px 0;
}

.clientend-question__buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  div {
    margin: 0 5px;
    border: 1px solid $base_color;
    color: $base_color;
    background-color: white;
    font-size: 0.95em;
    border-radius: 15px;
    padding: 5px 5px;
    cursor: pointer;
  }
  div.selected {
    background-color: $base_color;
    color: white;
  }
}

.xButton {
  text-align: center;
  position: absolute;
  border-radius: 50%;
  float: right;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 1px solid $base_color;
  top: -8px;
  right: -8px;
  cursor: pointer;

  * {
    margin-left: 0.5px;
    vertical-align: middle;
    color: $base_color;
  }
}

.client-button {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 30px;
  button {
    color: white;
    background: $base_color;
    font-size: 0.95em;
    border: 0;
  }
}

.clientEnd-activitiesList {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-bottom: 1px solid $base_color;
  padding-bottom: 10px;
}

.clientEnd-activitiesListNoBorder {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.clientEnd-header {
  padding: 15px 0 5px;
  text-align: center;
  font-weight: bold;
  font-size: 1.25em;
}

@media screen and (max-width: 768px) {
  .action-info {
    padding-top: 20px;
  }

  .actionHeader .actionClientInfo {
    padding-bottom: 10px;
    padding-top: 0px;
  }
}
