@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.container {
  padding-top: 50px;
  min-height: calc(100vh - 50px);
  background-color: white;

  &-inner {
    padding-top: 20px;
    min-height: calc(100vh - 50px);
    overflow-x: hidden;

    .back {
      margin: 0 20px;
      color: $base_color;

      .icon {
        position: relative;
        top: -2px;
      }
    }

    &-info {
      margin: 30px 20px 20px;

      &-date {
        
        &-datum {
          color: $base_color;
          display: inline-block;
          width: 100px;
          padding-bottom: 5px;
        }

        &-hodina {
          color: $base_color;
          padding-left: 20px;
        }
      }

      &-supervisor {
        padding-bottom: 15px;
        border-bottom: 1px solid $base_color;

        &-header {
          color: $base_color;
          display: inline-block;
          width: 100px;
        }
      }
    }

    &-stats {
      margin: 0px 20px;

      &-header {
        color: $base_color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        &-total {
          font-size: 20px;
          font-weight: bold;
        }

        .icon {
          position: relative;
          top: -2px;
          margin-left: 10px;          
        }
      }
    }

    &-comment {
      margin: 30px 20px;

      &-head {
        color: $base_color;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .container-inner {
    font-size: 12px;
  }
}
/*
@media screen and (max-width: 320px) {
  .container-inner {
    font-size: 10px;
  }
}
*/