@import "../../../config";

.interNDA-form-background {
  background-color: white;
  margin: 80px auto 0 auto;
  width: 100%;
}

.interNDA-answer-dropdown {
  padding-left: 10px;
  width: 30%;
  .ant-dropdown-trigger {
    max-width: 230px;
    border: 2px solid $base_color;
    color: white;
    background-color: $base_color;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
    border-radius: 32px;
    i {
      position: relative;
      top: -3px;
      right: -5px;
    }
  }
}

.interNDA-questions {
  padding-bottom: 20px;
}

.interNDA-question {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
}

.interNDA-form-wrapper {
  overflow-x: hidden;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 40px;
  h2 {
    padding: 0 0 20px;
    text-align: center;
    margin: 0 auto;
    color: inherit;
    font-size: 30px;
  }
  .back-button {
    margin-top: 20px;
    font-size: 16px;
    color: #41aea6;
    font-weight: bold;
  }
}

.interNDA-footer {
  text-align: center;
  padding-top: 20px;
}

.interNDA-footer .save-measurement {
  border-color: $base_color;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 1.2rem;
  background-color: $base_color;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
}

.interNDA-footer .remove-measurement {
  border-color: #ae4141;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 1.2rem;
  background-color: #ae4141;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 20px;
}

.measurement-date {
  text-align: center;
  padding-bottom: 30px;
}

.measurement-date-label {
  margin: 10px 0px 10px 0;
  font-size: 16px;
  color: #41aea6;
  font-weight: bold;
}

.measurement-date-data {
  font-weight: 800;
  font-size: 18px;
}

.measurement-date-picker .ant-input {
  text-align: center;
}

.measurement-date-picker .ant-calendar-picker-icon {
  top: 45%;
  color: $base_color;
  cursor: pointer;
}

.mobileView {
  display: none;
}

.desktopView {
  display: block;
}

@media screen and (max-width: 768px) {
  .desktopView {
    display: none;
  }

  .mobileView {
    display: block;
  }

  .interNDA-form-background {
    margin: 50px auto 0 auto;
  }

  .interNDA-answer-dropdown {
    width: 50%;
  }

  .interNDA-form-wrapper .back-button {
    font-size: 11px;
    font-weight: normal;
    margin-top: 12px;
    color: $base_color;
  }

  .interNDA-form-wrapper .anticon {
    font-size: 14px;
    padding-right: 5px;
  }

  .measurement-date-label {
    font-size: 14px;
  }
  
  .measurement-date-data {
    font-size: 14px;
  }

  .interNDA-form-wrapper h2 {
    font-size: 18px;
    padding-bottom: 16px;
  }

  .interNDA-questions {
    padding-bottom: 0px;
  }

  .interNDA-question {
    font-weight: normal;
    font-size: 14px;
  }

  .interNDA-footer {
    padding-top: 20px;
  }

  .interNDA-footer .save-measurement {
    height: 35px;
    font-size: 14px;
    font-weight: normal;
  }

  .interNDA-footer .remove-measurement {
    height: 35px;
    font-size: 14px;
    font-weight: normal;
  }
}
