.rented-accessories-detail-container {
  margin: 30px 20px 20px;

  .accessory-info-label {
    color: #41aea6;
    display: inline-block;
    width: 110px;
    padding-bottom: 10px;
    vertical-align: top;
  }

  .accessory-detail-photo-container {
    margin-bottom: 10px;
    display: inline-block;
  }

  .rented-accessories-active-button {
    height: 35px;
    font-size: 12px;
    color: white;
    font-weight: normal;
  }

  .rented-accessories-inactive-button {
    height: 35px;
    font-size: 12px;
    color: #41aea6;
    font-weight: normal;
  }

  .right-button {
    margin-left: 10px;
  }
}
