@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.plan-main {
  min-height: calc(100vh - 50px);
  background-color: white;
}

.top-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-family: 'Montserrat', arial;
}

.user_pic,
.user_name,
.user_activities {
  font-size: 10px;
}
// PRVY BLOK
.user_pic {
  margin-right: 20px;
  max-width: 25vw;
}
.profile_pic {
  display: block;
  margin: 0 auto;
  width: 80%;
}
.profile_pic img {
  width: 100%;
  border-radius: 50%;
}
.profil {
  color: $base_color;
  text-align: center;
  font-weight: bold;
}
//DRUHY BLOK
.user_name {
  max-width: 35vw;
}

.klienti {
  color: $base_color;
  font-size: 1.25em;
}
.user_name div {
  word-break: break-word;
}

.meno {
  font-size: 1.5em;
  font-weight: bold;
  color: $base_color;
}
.mesto {
  font-size: 1.25em;
}
// TRETI BLOK
.user_activities {
  max-width: 35vw;
  margin-left: 10px;
  color: $base_color;
  left: 4px;
}

.lekcie,
.skolenia {
  display: inline-block;
  margin: 0 5px;
  text-align: center;
}

.tyzden {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.count {
  border-bottom: 1px solid $base_color;
  font-size: 19px;
  font-weight: bold;
}

.plan-topPanel {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.button {
  background-color: transparent;
  border: none;
  color: $base_color;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.btn-pressed {
  border-bottom: 2px solid $base_color;
}

.omamaWeek {
  display: flex;
  padding-right: 10px;
  align-items: center;
  color: $base_color;
  font-weight: bold;
  font-size: 16px;
}

.actionsContainer {
  overflow: auto;
  height: calc(100vh - 140px);
}

.pridatButton {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.week-navigation-left {
  display: flex;
  padding-right: 5px;
  font-size: 20px;
  align-items: center;
  color: #060606;
}

.week-navigation-right {
  display: flex;
  padding-left: 5px;
  font-size: 20px;
  align-items: center;
  color: #060606;
}

.week-navigation-left.big {
  font-size: 24px;
}

.week-navigation-right.big {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .weekPicker {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .omamaWeekPicker {
    margin-left: 15px;
  }
}
