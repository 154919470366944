@import '../../config';

.rented-accessories-container {
  padding: 30px;
  padding-top: 120px;
  margin: 0 auto;
  background-color: white;
}

.rented-accessories-container .table-manipulation-mobile {
  display: none;
}

.rented-accessories-container .table-manipulation-desktop {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  padding-bottom: 20px;
}

.omama-profile .rented-accessories-container {
  padding: 0;
  margin: 0 auto;
  background-color: unset;
}

.accessories-table-mobile {
  display: none;
}

.rented-accessories-active-button {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 40px;
  font-size: 14px;
  background-color: #41aea6;
  color: white;
  font-weight: bold;
}

.rented-accessories-active-button:focus {
  color: white;
  background-color: #41aea6;
}

.rented-accessories-active-button:hover {
  border-color: #2b8c84;
  background-color: #2b8c84;
  color: white;
}

.rented-accessories-inactive-button {
  border-color: black;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 40px;
  font-size: 14px;
  background-color: white;
  color: black;
  font-weight: bold;
}

.rented-accessories-inactive-button:focus {
  color: black;
  background-color: white;
}

.rented-accessories-inactive-button:hover {
  border-color: black;
  background-color: #d6fcf9;
  color: black;
}

.rented-accessories-table-desktop {
  overflow: hidden;
}

.rented-accessories-table-desktop .ant-table {
  overflow-x: scroll;
}

.rented-accessories-table-desktop .right-button {
  margin-left: 10px;
}

.rented-accessories-table-desktop {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 200px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    min-width: 100px;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    min-width: 135px;
  }
  .ant-table-thead > tr > th:nth-child(5) {
    min-width: 100px;
  }
  .ant-table-thead > tr > th:nth-child(6) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(7) {
    min-width: 150px;
  }
  tbody tr {
    cursor: default;
  }
  .ant-table-thead {
    height: 68px;
  }
}

.rented-accessories-image {
  max-height: 100px;
}

.accessories-checkbox {
  display: flex;
  align-items: center;
}

.icon {
  margin: 0 10px 0 0;
}

.rented-accessories-search-container {
  min-width: 250px;
  width: 33%;
  max-width: 350px;
}

.rented-accessories-input-container {
  width: 50%;
  padding-bottom: 16px;

  .ant-input.accessories-input.search {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .rented-accessories-container {
    padding: 10px;
    padding-top: 100px;
    margin: 0 auto;
  }

  .omama-profile .rented-accessories-container {
    padding: 0;
  }

  .rented-accessories-container .table-manipulation-mobile {
    display: block;
    padding: 20px;
  }

  .rented-accessories-container .table-manipulation-desktop {
    display: none;
  }

  .rented-accessories-table-desktop {
    display: none;
  }

  .accessories-table-mobile {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }

  .accessories-table-mobile table tr th {
    font-size: 12px;
    font-weight: normal;
    color: $base_color;
  }

  .accessories-table-mobile table tr td {
    font-size: 12px;
    font-weight: normal;
    color: black;
  }

  .accessories-table-mobile-icon {
    color: #41aea6;
    padding-bottom: 8px;
  }
}
