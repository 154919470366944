@import '../config';

.navbar {
  display: flex;
  width: inherit;
  height: 80px;
  width: 100%;
  line-height: 80px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: black;
  .menu {
    display: none;
  }
  .just-mobile {
    display: none;
  }
}

.navbar_items {
  list-style: none;
  padding-left: 20px;
  margin: 0;
  width: 78%;
  .admin_headline {
    display: none;
  }
}

.userProfilePicture {
  img {
    width: 50px;
    border-radius: 50%;
  }
}

.navbar_center {
  display: inline-flex;
  text-align: center;

  .navbarActive {
    border-bottom: 2px solid;
    color: $base_color;
  }

  li {
    display: inline-block;
    text-align: center;
    margin: 0 14px;
    cursor: pointer;
    font-size: 1.2vw;
    height: 55px;
    line-height: 80px;
    color: white;
    user-select: none;
  }

  li:hover {
    border-bottom: 2px solid;
    color: $base_color;
  }
}

.user-action-container {
  display: flex;
  gap: 5px;
  margin-left: auto;
  padding-right: 20px;
  cursor: pointer;

  .user-action-icon {
    color: white;
    line-height: unset;
    font-size: 12px;
  }
}

.user-action-options {
  display: flex;
  width: 158px;
  padding: 24px 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 1001;
  line-height: normal;
  border-radius: 25px;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.06);
  background-color: white;
}

.settings-option {
  color: #091817;
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 2px solid white;
}

.settings-option:hover {
  border-bottom: 2px solid #091817;
}

.sign-out-option {
  color: #e41849;
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: 2px solid white;
}

.sign-out-option:hover {
  border-bottom: 2px solid #e41849;
}

.logo_w {
  margin-top: 6vh;
  width: 100px;
  height: 100px;
  margin-left: 90px;
}

.logoheader_w {
  margin-left: 15px;
  width: 80px;
  height: 80px;
}

#sign_out_button {
  color: #41aea6;
  cursor: pointer;
  font-size: 1.2vw;
  height: 55px;
  line-height: 80px;
  position: absolute;
  right: 15px;
}

.sign_out_button:hover {
  border-bottom: 2px solid;
  color: $base_color;
}

// .ant-descriptions-item-content {
//   color: white;
// }

// .ant-descriptions-item-label {
//   color: white;
// }

.respoMenu {
  display: none;
}

.accessories-notification {
  display: flex;
  position: relative;
  &-new {
    position: absolute;
    top: 24px;
    right: -9px;
    height: auto;
    width: auto;
    min-width: 17px;
    border-radius: 50% 50%;
    background-color: rgb(204, 34, 34);
    text-align: center;
    font-family: 'Montserrat', arial;
    color: white;
    font-size: 7px;
    font-weight: bold;
    line-height: 13px;
    border: 2px solid #272928;
  }
  &-seen {
    position: absolute;
    top: 24px;
    right: -9px;
    height: auto;
    width: auto;
    min-width: 17px;
    border-radius: 50% 50%;
    background-color: white;
    text-align: center;
    font-family: 'Montserrat', arial;
    color: black;
    font-size: 7px;
    font-weight: bold;
    line-height: 13px;
    border: 2px solid #272928;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    height: 50px;
    padding: 0px 20px;
    align-items: center;
    background: #272928;
    top: 0px;
    left: 0px;
    z-index: 998;
    .navbar_items {
      height: 100%;
      text-align: start;
      display: flex;
      align-items: center;
      padding-left: 0;
      .navbar_center {
        li {
          display: none;
        }
      }
    }

    .just-mobile {
      display: block;
    }

    .userProfilePicture {
      margin-right: 9px;
      margin-left: -4px;
      img {
        width: 40px;
        border-radius: 50%;
        margin-left: 0px;
      }
    }

    .respoMenu {
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
      &-closeIcon {
        margin-top: 0px !important;
        margin-right: 0px !important;
      }
    }

    #navbar_center_resp {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      position: absolute;
      padding-top: 50px;
      top: 0px;
      left: 0px;
      bottom: 0px;
      margin: 0 0;
      padding-bottom: 800px;
      background-color: white;

      li {
        position: relative;
        display: block;
        left: 0px;
        padding: 0 20px;
        text-align: center;
        font-size: 22px;
        font-family: 'Montserrat', arial;
        color: $base_color;
        font-weight: bold;
        line-height: 55px;
      }

      li:hover {
        background-color: $base_color;
        color: white;
        margin: 0px 0px;
      }
    }

    .user-action-container {
      display: none;
    }

    .admin_headline {
      display: inline-block;
      text-align: center;
      margin: 0;
      color: white;
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;
    }

    #admin_headline_hidden {
      display: none;
    }

    .menu {
      display: block;
      margin: 0px;
      width: 50px;

      .burger {
        margin-left: 10px;
        height: 40px;
        padding: 0px;
      }
    }
  }

  .accessories-notification {
    display: inline-block;
    &-new {
      top: -4px;
      right: -20px;
      height: auto;
      width: auto;
      min-width: 34px;
      padding: 6px;
      background-color: rgb(204, 34, 34);
      color: white;
      font-size: 13px;
      line-height: 18px;
      border: 2px solid white;
    }
    &-seen {
      top: -4px;
      right: -20px;
      height: auto;
      width: auto;
      min-width: 34px;
      padding: 6px;
      background-color: $base_color;
      color: white;
      font-size: 13px;
      line-height: 18px;
      border: 2px solid white;
    }
  }
}

@media screen and (min-width: 1000px) {
  .accessories-notification {
    &-new {
      top: 21px;
      right: -14px;
      height: auto;
      width: auto;
      min-width: 22px;
      font-size: 9px;
      padding: 2px;
    }
    &-seen {
      top: 21px;
      right: -14px;
      height: auto;
      width: auto;
      min-width: 22px;
      font-size: 9px;
      padding: 2px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .accessories-notification {
    &-new {
      top: 18px;
      right: -17px;
      height: auto;
      width: auto;
      min-width: 25px;
      font-size: 11px;
      padding: 3px;
      line-height: 15px;
    }
    &-seen {
      top: 18px;
      right: -17px;
      height: auto;
      width: auto;
      min-width: 25px;
      font-size: 11px;
      padding: 3px;
      line-height: 15px;
    }
  }
}

@media screen and (min-width: 1800px) {
  .accessories-notification {
    &-new {
      top: 14px;
      right: -17px;
      height: auto;
      width: auto;
      min-width: 27px;
      font-size: 12px;
      padding: 4px;
      line-height: 16px;
    }
    &-seen {
      top: 14px;
      right: -17px;
      height: auto;
      width: auto;
      min-width: 27px;
      font-size: 12px;
      padding: 4px;
      line-height: 16px;
    }
  }
}
