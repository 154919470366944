@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.container {
  &-inner {
    &-info {
      &-item {
        margin-bottom: 10px;
        &-title {
          color: $base_color;
        }
      }
    }
    &-button {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $base_color;
      margin: 0 15px;
      .butt {
        transform: scale(0.8);
      }
    }
    &-results {
      margin: 0 15px;
      &-header {
        font-weight: normal;
        text-align: center;
      }
      &-title {
        font-weight: bold;
        color: $base_color;
        margin: 14px 0px;
      }
    }
  }
}
