@import '../../config';

.omama-list {
  columns: 2 auto;
  font-size: 14px;
}

.accessories-detail-button {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 40px;
  font-size: 14px;
  background-color: white;
  color: #41aea6;
  font-weight: bold;
  margin-bottom: 15px;
}

.accessories-detail-button-save {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 16px;
  background-color: #41aea6;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
}

.accessories-detail-button-remove {
  border-color: #ae4141;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 16px;
  background-color: #ae4141;
  color: white;
  font-weight: bold;
  margin-bottom: 15px;
}

.second-button {
  margin-left: 10px;
}

.accessories-detail-button:hover,
.accessories-detail-button:focus,
.accessories-detail-button-save:hover {
  color: #41aea6;
  background-color: white;
}

.accessories-detail-button-remove:hover {
  color: #ae4141;
  border-color: #ae4141;
  background-color: white;
}

.accessory-detail-item .ant-descriptions-item-label {
  color: #41aea6;
  font-weight: bold;
}

.accessory-detail-item .ant-descriptions-item-colon::after {
  content: '';
}

.accessory-detail-item .ant-descriptions-item-label::after {
  content: '';
  margin: 0;
}

.accessory-detail-item .ant-descriptions-item-content {
  color: black;
  display: block;
  font-size: initial;
  padding-top: 8px;
}

.ant-descriptions-row > td.accessory-detail-item {
  display: block;
}

.accessory-detail-add-photo-field {
  margin-bottom: 15px;

  .upload-photo-button-container {
    width: 30%;
    margin: 0 auto;
  }
  .choose-photo {
    border: 1px solid #41aea6;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    margin: 10px auto;
    padding: 5px;
    color: #41aea6;
    cursor: pointer;
  }

  .photo-icon {
    height: 5vh;
    display: block;
    margin: 10px auto;
  }

  i {
    padding-bottom: 3px;
    cursor: pointer;
  }
}

.upload-photo-container {
  display: block;
  text-align: center;
  position: relative;
  width: 66%;
  margin: 0 auto;
}

.uploaded-photo {
  width: 100%;
}

.upload-photo-container .xButton {
  position: absolute;
  right: -12px;
  top: -12px;
}

.upload-photo-container {
  display: block;
  text-align: center;
  position: relative;
  width: 66%;
  margin: 0 auto;
}

.uploaded-photo {
  width: 100%;
}

.upload-photo-container .xButton {
  position: absolute;
  right: -12px;
  top: -12px;
}

.accessory-detail-textarea {
  max-width: 600px;

  div {
    width: 100%;

    &.row {
      display: flex;
      justify-content: space-between;
    }
  }

  textarea {
    margin-bottom: 25px;
    box-shadow: 0px 9px 40px -19px rgba(0, 0, 0, 0.75);
    color: rgb(128, 128, 128);
    font-family: Montserrat, arial;
    border-color: #41aea6;
    border-width: 2.5px;
    border-radius: 13px;
  }
}

.accessory-detail-dropdown {
  padding-left: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  .ant-dropdown-trigger {
    max-width: 260px;
    border: 2px solid #41aea6;
    color: white;
    background-color: #41aea6;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
    border-radius: 32px;
    i {
      position: relative;
      top: -3px;
      right: -5px;
    }
  }
}

.accessory-detail-message {
  padding-top: 10px;
  font-weight: bold;
}

.omama-checkbox-container {
  padding: 5px;
  width: 270px;
}

.rented-accessory-history-modal .ant-modal-header {
  padding-right: 46px;
}

h5.ant-typography {
  color: #41aea6;
}

@media screen and (max-width: 768px) {
  .omama-list {
    columns: unset;
    margin: 0 auto;
  }

  .accessories-detail-button {
    border-color: #41aea6;
    box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
    border-radius: 24px;
    border-width: 1px;
    height: 40px;
    font-size: 12px;
    background-color: white;
    color: #41aea6;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .omama-checkbox-container {
    width: unset;
  }

  .accessory-detail-message {
    font-size: 14px;
  }
}
