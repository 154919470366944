@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

* {
  font-family: 'Montserrat', arial;
}

.pointer {
  cursor: pointer;
}

.statsBackground {
  background-color: $backgroundColor;
  background-size: cover;
  //
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  //

  position: relative;
  height: -webkit-fill-available;
}

.boxStats {
  width: 95vw;
  min-height: calc(100vh - 80px);
  // height: -webkit-fill-available;
  //   height: -webkit-fill-available; nahradit miesto min-height !!!!!
  background-color: $backgroundColor;
  margin: 0 auto;
  // overflow-y: auto;
  @media screen and (max-width: 768px) {
    background-color: $backgroundColorMobile;
  }
}

.navigationStats {
  padding: 0 10px;
  padding-top: 15px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  * {
    font-family: 'Montserrat', arial;
    font-weight: bold;
    font-size: 1.3em;
    color: $base_color;
  }
}

.mainStats {
  padding: 0 30px;
}

.statsTab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  padding: 20px 0;
  color: gray;
}

.firstSection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 35px;
}

.userCounter {
  min-width: 190px;
  width: 12vw;
  padding: 5px 0 15px 0;
  background-color: white;
  border-radius: 15px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.userCounter.comCounter {
  transform: scale(0.9);
}

.firstCounter {
  text-align: center;
  color: $base_color;
  font-weight: bold;
  margin-bottom: 18px;
  * {
    margin: 0 10%;
  }
}

.counterMainTitle {
  text-align: center;
  color: $base_color;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 8px;
}

.counterMainTitle.counterCommunityTitle {
  text-transform: capitalize;
}

.userCounterInnerText {
  font-size: 1.4em;
  margin: 0 10%;
}

.firstNumber {
  border-bottom: 0.5px solid $base_color;
  font-size: 2.4em;
}

.secondNumber {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 4px;
}

.secondaryCounters {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  div {
    div {
      text-align: center;
    }
  }
}

// SECOND section - KLIENTI dropdown
.secondSection {
  h4 {
    color: $base_color;
    margin: 15px 0 15px 50px;
    font-size: 1.3em;
    font-weight: bold;
  }
}

.communitiesSection {
  padding-bottom: 40px;
}

.clientsStats {
  background-color: white;
  border-radius: 15px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 0 15px 0;
  margin-bottom: 40px;
  &-info {
    margin-left: 50px;
  }
}

.ageGroups {
  padding: 0 calc(100% - 90%);
}

.singleAgeGroup {
  flex: 1 0 25%;
  margin-bottom: 20px;
}

.calculatedPadding {
  padding: 0 calc((100% - 120px) / 2);
}

.rangeNumber {
  color: $base_color;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 5px;
}

.rangeTitle {
  font-size: 0.9em;
  border-top: 0.5px solid gray;
  text-align: center;
  padding-top: 3px;
}

.communities {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  &-title {
    text-align: center;
    margin-left: 0px !important;
  }
  .noDataInfo {
    font-size: 16px;
  }
}

.singleCommunity {
  flex: 1 0 18%;
}

.communityNumber {
  color: $base_color;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.communityTitle {
  max-width: 150px;
  font-size: 1.3em;
  border-top: 0.5px solid gray;
  text-transform: capitalize;
  text-align: center;
  padding: 3px 10px 0;
  word-break: break-word;
  margin: 0 auto;
}

.activitiesStats-list {
  margin: 0 auto;
  width: 85%;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  div {
    margin: 3px 0;
  }
}

.activitiesStats-count {
  color: #41aea6;
  font-weight: bold;
  margin-right: 8px;
}

.omamasStats-list {
  width: 200px;
  margin: 0 auto;
}

.evaluationStats-answers {
  padding: 0 50px;
  display: flex;
  justify-content: space-around;
}

.skriningStatsTable {
  border: 1px solid black;
  margin-left: calc((100% - 800px) / 2);
  margin-right: calc((100% - 800px) / 2);
  box-sizing: border-box;
  &-flexRow {
    display: flex;
    flex-direction: row;
  }
}

.skriningStatCell {
  border: 0.5px solid black;
  padding: 3px;
  padding-left: 10px;
  font-weight: bolder;
}
th {
  padding: 5px;
  font-weight: bold;
}

.action-answers-percentage {
  font-weight: bold;
  font-size: 1.1em;
  color: $base_color;
  border-bottom: 0.5px solid black;
}

.omamaStatisticsTable .table {
  border-radius: 15px;
  margin-bottom: 40px;
  .centeredColumn {
    text-align: center;
  }
  .ant-table-thead {
    height: 50px;

    .ant-table-column-title {
      word-break: normal;
    }
  }
  .ant-table-row {
    height: 40px;
  }
}

.desktopOmamaStats {
  display: block;
  .desktopOmamaStatsTable {
    .ant-table-thead > tr > th {
      min-width: 100px;
    }
    .ant-table-thead > tr > th:nth-child(3),
    .ant-table-thead > tr > th:nth-child(6),
    .ant-table-thead > tr > th:nth-child(7) {
      min-width: 120px;
    }
    .ant-table-thead > tr > th:nth-child(5),
    .ant-table-thead > tr > th:nth-child(9) {
      min-width: 130px;
    }
    .ant-table-thead > tr > th:nth-child(8) {
      min-width: 140px;
    }
    .ant-table-small {
      border-radius: 14px;
    }
  }
}

.mobileOmamaStats {
  display: none;
}

.ant-modal-confirm-info,
.ant-modal-confirm-body > .anticon {
  color: #41aea6 !important;
}

.statsOverall .hidePercentage {
  display: none;
}

@media screen and (max-width: 992px) {
  .mainStats {
    padding: 0px;

    .clientsStats {
      &-header {
        margin-left: unset;
        text-align: center;
      }
      &-info {
        margin-right: 20px;
        margin-left: 20px;
        font-size: 14px;
      }
    }

    .firstSection,
    .communities {
      flex-wrap: wrap;

      .userCounter {
        min-width: 150px;
        transform: scale(0.9);
        .firstCounter {
          font-size: 11px;
        }
        .userCounterInnerText {
          font-size: 1em;
        }
      }
    }
  }

  .desktopOmamaStats {
    display: none;
  }

  .mobileOmamaStats {
    display: block;
    margin-bottom: 40px;

    .mobileTableHeader {
      margin-left: unset;
      text-align: center;
    }

    .statTable {
      &-statRow {
        display: flex;
        align-items: center;
        padding: 2px;
        border-bottom: 0.5px solid #e8e8e8;
        &-title {
          width: 70%;
        }
        &-number {
          width: 30%;
          text-align: center;
        }
      }
    }

    .statTable div:first-child span:first-child {
      color: #000;
    }

    .statTable .statTable-statRow:last-child {
      border-bottom: unset !important;
    }

    .statsOverall,
    .statsThisYear {
      padding-bottom: 5px;
      border-radius: 15px;
      box-shadow:
        0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);
    }

    .mobileOmamaStatsTotal:first-child .mobileOmamaLevel {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .mobileOmamaLevel {
      background-color: $base_color;
      color: white;
      padding: 5px 10px;
    }

    .mobileOmamaTable {
      .ant-table-small {
        border: unset;
        border-radius: unset;
      }

      .ant-table-row-level-0 {
        color: $base_color;
        font-weight: bold;
      }

      .ant-table-row-level-0 td:last-child {
        text-align: left;
      }

      tr.ant-table-expanded-row {
        font-size: 12px;
      }

      td {
        padding: 4px !important;
      }

      .ant-table-expand-icon-th,
      .ant-table-row-expand-icon-cell {
        padding-right: 8px !important;
      }

      .ant-table-thead {
        display: none;
      }
    }
  }

  .skriningStatsTable {
    border: unset;
    margin: unset;
    box-sizing: unset;

    &-flexRow {
      flex-direction: column;
      align-items: center;

      .skriningStatCell {
        width: 250px !important;
        border: 0.5px solid #e8e8e8;
        //color: rgba(0, 0, 0, 0.65);
      }
      .screeningName {
        padding: 5px 3px 3px;
        text-align: center;
        color: $base_color;
        //border: none;
      }
    }
  }

  #skriningStatsTableHeader {
    display: none;
  }
}
