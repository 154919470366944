@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.top-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-family: 'Montserrat', arial, serif;
}

.user_pic,
.user_name,
.user_activities {
  font-size: 10px;
}
// PRVY BLOK
.user_pic {
  margin-right: 20px;
  max-width: 25vw;
}
.profile_pic {
  display: block;
  margin: 0 auto;
  width: 80%;
}
.profil {
  color: $base_color;
  text-align: center;
  font-weight: bold;
}
//DRUHY BLOK
.user_name {
  max-width: 35vw;
}

.klienti {
  color: $base_color;
  font-size: 1.25em;
}
.user_name div {
  word-break: break-word;
}

.meno {
  font-size: 1.5em;
  font-weight: bold;
  color: $base_color;
}
.vek {
  font-size: 1.25em;
}
// TRETI BLOK
.user_activities {
  max-width: 35vw;
  // margin-top: 3px;
  margin-right: 5vw;
  color: $base_color;
}

.lekcie,
.skolenia {
  display: inline-block;
  margin: 0 5px;
  text-align: center;
}

.tyzden {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.count {
  border-bottom: 1px solid $base_color;
  font-size: 19px;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.button {
  background-color: transparent;
  border: none;
  color: $base_color;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.btn-pressed {
  border-bottom: 2px solid $base_color;
}

.clientProfile-actionHeadline {
  font-weight: bold;
  color: $base_color;
}

.clientProfile-actionList {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  div {
    width: 23%;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    margin: 2px 3px;
  }
}
