@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

.styleText .ant-descriptions-item-label {
  color: $base_color;
  // text-transform: uppercase;
  font-weight: bold;
}

.styleText .ant-descriptions-item-content {
  color: black;
  display: block !important;
  font-size: initial;
  text-align: left;
}
.styleText .ant-descriptions-item-content img {
  margin-top: 10px;
}


.styleText .ant-descriptions-item-container {
  display: flex;
  flex-direction: column;
}

.styleName .ant-descriptions-item-content {
  color: $base_color;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Montserrat', arial;
  width: 100%;
}



.styleVek .ant-descriptions-item-label {
  color: grey;
  // text-transform: uppercase;
  font-family: 'Montserrat', arial;
  margin-left: 10vw;
}

.styleVek .ant-descriptions-item-content {
  color: grey;
  font-weight: bold;
  font-family: 'Montserrat', arial;
}

.ant-descriptions-row > td.styleName {
  margin-bottom: -3vh;
}

// .ant-descriptions-row > td.styleVek {
//   padding-bottom: 5vh;
// }

.ant-descriptions-row > td.styleText {
  display: block;
}

.ant-descriptions-row > td.displayItem {
  display: block;
}

.styleIcon.anticon {
  float: left;
  font-size: 1.5em;
  margin-top: 5vh;
  margin-right: 2vw;
}

.name {
  padding-right: 60vw;
}

.styleDescriptions.ant-descriptions {
  margin: auto;
  width: 100%;
  height: 100vh;
}

.styleDescriptions-mobile.ant-descriptions {
  height: initial;
}

.styleActivityType p {
  margin-right: 5vw;
  color: $base_color;
  font-weight: bold;
  font-size: 30px;
  margin-left: 5vw;
  padding: 2.5vh 0;
  margin-bottom: 0;
}

.styleActivityType .ant-descriptions-item-content {
  font-size: 20px;
  color: grey;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 6vh;
}

.ant-descriptions-row > td.styleActivityType {
  background: white;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 0;
}

.styleRightIcon.anticon {
  color: dimgrey;
  font-size: 1.5em;
  margin-right: 4vw;
  margin-left: 1vw;
}

.activityTypeInList {
  flex: 1.1;
  font-size: 17px;
}

.activityGroupPeriod {
  font-weight: bold;
  color: grey;
  font-size: 18px;
  margin-left: 8vw;
}

.activityGroupDivider {
  background: #41aea6;
  height: 2px;
}

.activityGroupItem {
  text-align: left;
  line-height: 5vw;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #41aea6;
  font-weight: bolder;
  margin-left: 8vw;
}

.activityGroupsTopComponent {
  padding: 40px 0px;
  background-color: #e5e5e6;
  width: 100%;
}
.activityInfo-main{
  overflow:auto;
  height: calc(100vh - 70px);
}

.activityInfo-main-mobile{
  height: 100%;
}

.activityGroupWrapper {
  padding-left: 5vw;
  padding-right: 5vw;
}
.activityTypeHeaderIcon {
  color: #41aea6;
  font-weight: 800;
  font-size: 8vw;
  margin-left: 3vw;
  margin-right: 3vw;
}
.activityTypeHeaderWrapper {
  display: flex;
  align-items: center;
}

.activityTypeHeaderText {
  margin-right: 3vw;
  margin-left: 0vw;
  font-size: 6.5vw !important;
}

.activityPhoto {
  margin-bottom: 5px;
  height: auto;
  width: 100%;
}

.aktivityInfoPdf {
  .aktivityText {
    padding-top: 5px;
  }
}

.activitiesContainer {
  background: white;
  padding-top: 60px;
  font-weight: bold;
}