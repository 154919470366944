@import '../../config';

.plainBackground {
  background-color: white;
}

.development-stages-main {
  background-color: $backgroundColor;
  padding: 40px;
  padding-top: 100px;
  min-height: 100vh;
  text-align: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    background-color: $backgroundColorMobile;
  }
}

.developmentStage {
  border-bottom: 2px solid $base_color;
  width: 50%;
  margin: auto;
  padding: 10px 0px;
  color: $base_color;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .developmentStage {
    width: 80%;
  }
}
