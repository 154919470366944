@import '../../config';

.screeningTable {
  margin: 0;
  width: 100%;
  font-size: 12px;

  .tableHeader,
  .tableRow {
    display: flex;
    align-items: center;
    height: 48px;
  
    &-screening {
      width: 40%;
      text-align: left;
    }

    &-plan {
      width: 28%;
      text-align: center;
    }

    &-completed {
      width: 32%;
      text-align: center;
      justify-content: center;
    }
  }

  .tableHeader {
    font-weight: bold;
    color: $base_color;
  }

  .tableRow {
    border-bottom: 1px solid $base_color;

    &-completed {
      display: flex;
      gap: 10px;
      color: $base_color;

      .arrowIcon {
        align-self: center;
      }

      .plusIcon {
        width: 19px;
        height: 19px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .screeningTable {
      font-size: 16px
  }
}