@import "../config";

.preschool-screening-dropdown-answer {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  .ant-dropdown-trigger {
    max-width: 230px;
    border: 2px solid $base_color;
    color: $base_color;
    background-color: white;
    margin: 0 auto;
    text-align: center;
    padding: 4px;
    border-radius: 4px;
    i {
      position: relative;
      top: -3px;
      right: -5px;
    }
  }
}

.preschool-screening-question-title-mobile {
  width: 90%;
  font-size: 12px;
  font-weight: bold;
  height: 50px;
}

.preschool-screening-checkbox-mobile {
  background-color: #ccf1ee;
}

.preschool-screening-input-mobile {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .preschool-screening-dropdown-answer {
    font-size: 12px;
    .ant-dropdown-trigger {
      border: 1px solid $base_color;
      padding-left: 8px;
      i {
        position: unset;
        padding-top: 3px;
      }
    }
  }

  .preschool-screening-dropdown-answer-mobile {
    display: flex;
  }
}