.omama-checkbox {
  padding: 10px;
  border: 2px solid black;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .info-icon {
    color: black;
  }
}

.info-icon-container {
  margin-left: auto;
  padding-right: 2px;
  padding-left: 10px;
}

.omama-checkbox-checked {
  padding: 10px;
  border: 2px solid #41aea6;
  color: white;
  border-radius: 20px;
  background-color: #41aea6;
  cursor: pointer;
  display: flex;
  align-items: center;

  .info-icon {
    color: white;
  }
}

.omama-checkbox-add {
  padding: 10px;
  border: 2px solid rgb(11, 135, 55);
  color: rgb(11, 135, 55);
  border-radius: 20px;
  background-color: rgba(172, 255, 201, 0.498);
  cursor: pointer;
  display: flex;
  align-items: center;

  .info-icon {
    color: rgb(11, 135, 55);
  }
}

.omama-checkbox-remove {
  padding: 10px;
  border: 2px solid rgb(112, 0, 0);
  color: rgb(112, 0, 0);
  border-radius: 20px;
  background-color: rgba(255, 149, 149, 0.495);
  cursor: pointer;
  display: flex;
  align-items: center;

  .info-icon {
    color: rgb(112, 0, 0);
  }
}

.info-icon {
  font-size: 20px;
  vertical-align: -1px;
}

.omama-checkbox-name {
  padding-left: 10px;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .omama-checkbox {
    border: 1px solid black;
  }

  .omama-checkbox-checked {
    border: 1px solid #41aea6;
  }

  .omama-checkbox-add {
    border: 1px solid rgb(11, 135, 55);
  }

  .omama-checkbox-remove {
    border: 1px solid rgb(112, 0, 0);
  }
}
