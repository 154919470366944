@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.actionDetail-info {
    //height: 30px;
    font-size: 14px;
    margin: 0 10px;
    display: inline-flex;
    padding-bottom: 10px;

    &-title {
        color: $base_color;
        padding-right: 15px;
        min-width: 70px;
    }

    &-firstInput {
        padding-right: 15px;
    }

    &-secondInput {
        color: $base_color;
    }
}