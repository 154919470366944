@import '../config';

.button {
  display: flex;
  min-height: 40px;
  font-size: 16px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.primary {
    color: white;
    border: 1px solid #41aea5;
    background-color: #41aea5;

    &:hover {
      border-color: #2b8c84;
      background-color: #2b8c84;
      color: white;
    }
  }

  &.secondary {
    color: #060606;
    border: 1px solid #060606;
    background-color: white;

    &:hover {
      background-color: #d6fcf9;
    }
  }

  &.go-back-button {
    display: flex;
    font-size: 16px;
    font-weight: bold;
  }

  img {
    padding-right: 8px;
  }
}

@media screen and (max-width: 768px) {
  .button {
    font-size: 14px;
  }
}
