.screeningRow{
  display: flex;
}

.screeningCell{
  width: 10%;
  border: 0.5px solid black;
  text-align: center;
  padding: 5px 0;
  font-weight: bold;
}

.screeningCell.green{
  background-color: rgb(0, 209, 0);
  }

.screeningCell.red{
  background-color: red;
}

.lightGrey{
  background-color: lightgrey;
}

.darkGrey{
  background-color: darkgrey;
}