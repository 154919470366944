@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import "../config";

.clientArticle {
  color: white;
  background: #41aea6;
  text-align: justify;
  padding: 10px;
  margin-bottom: 0;
}

.clientPersonCard {
  display: flex;
  font-family: "Montserrat", arial;
  background: white;
  border: none;
  padding-bottom: 10px;
}

.clientPersonCard p {
  margin-bottom: 0;
}

.clientCardImage img {
  max-width: 17vw;
  border-radius: 50%;
}

.section_one {
  display: flex;
  align-items: center;
  max-width: 35vw;
  padding: 0 10px;
  text-align: center;
}

.profile_change {
  color: #41aea6;
  padding-top: 5px;
}

.section_two {
  margin-top: -7px;
  width: 100%;
  .top-block{
    .user-name{
      color: #41aea6;
      font-weight: bold;
      font-size: 17px;
    }
  }

  .bottom-block{
    display: flex;
    justify-content: space-between;
    & > * >  * > .label {  
      color: #41aea6;
      font-size: 0.8em;
      padding-top: 4px;
      line-height: 0.5;
      word-break: normal;
    }
    & > .lessons{
      width: 40%;
    }
    & > .joined{
      width: 35%;
    }
    & > .next-lesson{
      width: 25%;
    }
  }
}

.user_name_info {
  font-size: 9px;
}

.section_three {
  max-width: 25vw;
  color: #41aea6;
  line-height: 1.1;
  text-align: center;
}


.lection_count {
  border-bottom: 1px solid #41aea6;
  padding-bottom: 4px;
  width: 150%;
}
.clientArticleHeader {
  color: black;
  text-align: left;
  padding: 5px 10px;
  font-weight: 600;
  position: relative;
}
#btn-screening-client {
    position: absolute;
    right: 10px;
    font-size: 16px;
    background: #41aea6;
    color: white;
    border: none;
  }

.clientArticleSubHeader {
  color: orange;
  float: left;
  padding: 10px;
  margin-bottom: 0;
  width: 150px;
  font-weight: 600;
  position: relative;
  text-align: center;
}

.clientArticleP {
  color: black;
  text-align: justify;
  margin-bottom: 0;
  padding: 0 10px 10px 10px;
  z-index: 2;
  position: relative;
}

.clientprofilecard-screeningy{
  display: flex;
  flex-wrap: wrap;
}