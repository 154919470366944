@import '../_config.scss';

.imageGalleryNav {
  width: 100%;
  display: flex;
}

.imageGalleryNavLevel {
  position: relative;
  &:not(.disabled) {
    .imageGalleryNavName {
      color: $base_color;
    }
  }
}

.imageGalleryNavArrow {
  float: left;
  position: absolute;
  font-size: 20px;
  left: 10px;
  top: 12px;
  font-weight: 600;
}

.imageGalleryNavName {
  margin-left: 40px;
  margin-bottom: 0;
  padding: 10px 0;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
}

.imageGalleryAdd {
  margin-left: 40px;
  // margin-bottom: 0;
  padding: 10px 0;
  color: $base_color;
  font-weight: 600;
  // text-align: left;
}
@media screen and (min-width: 750px) {
  .mobileOnly {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .mobileOnly {
    display: block;
  }

  .imageGalleryNav {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .imageGalleryNavArrow {
    font-size: 14px;
    color: $base_color;
  }

  .imageGalleryNavName {
    margin-left: 30px;
  }
}
