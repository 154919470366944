@import '../../config';

.client-milestones {
  text-align: left;

  .goBack {
    display: none;
  }

  .vertical-timeline-element-content {
    border-radius: 25px;
    box-shadow: none;
    padding: 24px;
  }

  .vertical-timeline-element-content-arrow {
    display: none;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    opacity: unset;
    left: 128%;
    top: 0px;
  }

  .vertical-timeline-element-icon .anticon {
    position: absolute;
    top: 19px;
    left: 13px;
    color: white;
  }

  .vertical-timeline-element-icon svg {
    width: 25px;
    height: 25px;
  }

  .milestone-edit {
    position: absolute;
    top: 10px;
    right: 15px;
    color: $base_color;
    cursor: pointer;
  }

  .save-milestone-change {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
  }

  .milestone-icon-save {
    color: white;
    padding: 5px;
    background-color: $base_color;
    border-radius: 20px;
    font-size: 30px;
    cursor: pointer;
  }

  .milestone-icon-no-save {
    color: white;
    padding: 5px;
    background-color: #ae4141;
    border-radius: 20px;
    font-size: 30px;
    cursor: pointer;
  }

  .milestone-textarea {
    height: unset;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  .milestone-choose-photo {
    border: 1px solid $base_color;
    border-radius: 10px;
    text-align: center;
    width: 125px;
    margin: 10px auto;
    padding: 5px;
    color: $base_color;
    cursor: pointer;
  }

  .milestone-photoIcon {
    height: 5vh;
    display: block;
    margin: 10px auto;
  }

  .milestone-photo-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    cursor: pointer;
  }

  .milestone-photo-container.new-photo {
    padding-left: 6px;
  }

  .milestone-photo {
    max-width: 98%;
    max-height: 80vh;
    margin: 5px;
  }

  .new-photo .milestone-photo {
    max-width: 90%;
  }

  .milestone-remove-photo {
    text-align: center;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: $base_color;
    background-color: white;
    border: 1px solid #41aea6;
    margin-left: -18px;
    margin-top: -8px;
    cursor: pointer;
  }

  .milestone-remove-photo .anticon {
    vertical-align: 0;
  }
}

@media screen and (max-width: 768px) {
  .client-milestones {
    margin: 50px auto 0 auto;
    padding: 0px 20px;
    padding-bottom: 20px;
    min-height: 100svh;

    .goBack {
      display: block;
      padding-bottom: 20px;
      padding-top: 8px;
    }

    .goBack .anticon {
      padding-top: 9px;
    }

    .vertical-timeline-element-content {
      border: 1px solid #ddd;
      box-shadow: 3px 3px 0 #ddd;
      padding: 16px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .client-milestones {
    .vertical-timeline {
      padding-left: 180px;
      max-width: 800px;
      margin: unset;
    }

    .vertical-timeline::before {
      margin-left: 180px;
    }

    .vertical-timeline-element-content .vertical-timeline-element-date {
      position: absolute;
      left: -260px;
      width: 180px;
    }

    .milestone-time {
      text-align: right;
    }

    .vertical-timeline-element-icon {
      top: 15px;
    }
  }
}
