@import '../../config';

.measurement-button {
  margin-top: 10px;
  background-color: #41aea6;
  color: white;
  padding: 0 16px;
  border-radius: 29px;
  font-size: 19px;
}

.measurement-button:hover {
  background-color: white;
  color: $base_color;
  border-color: $base_color;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.milestone-action-icons-container {
  display: flex;
  gap: 8px;
}

.milestone-action-icon {
  width: 14px;
  height: 14px;
}

.milestone-icon {
  width: 25px;
  height: 25px;
  margin-top: 8px;
  margin-left: 8px;
}

.milestone-icon-small {
  width: 22px;
  height: 22px;
  margin-top: 9px;
  margin-left: 9px;
}

.milestone-photo-detail {
  .ant-modal-body {
    padding: 0;
  }
}

.milestone-photo-detail-image img {
  width: 100%;
  position: relative;
}

.center-button {
  text-align: center;
}

.milestone-time {
  color: #091817;
}

.milestone-date {
  font-size: 16px;
}

.milestone-age {
  font-size: 14px;
}

.vertical-timeline-element-title {
  color: #091817;
}

.milestone-edit-icon {
  cursor: pointer;
  border: 1px solid #091817;
  border-radius: 50%;
  padding: 0.5px 5.5px 3.5px;
}

@media screen and (max-width: 768px) {
  .measurement-button {
    font-size: 14px;
  }

  .milestone-age {
    font-size: 12px;
  }
}
