@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import "../../config";

.loginButton.ant-btn {
  font-family: "Montserrat", arial;
  color: white;
  background: $base_color;
  margin: 0 auto;
  margin-top: 5vh;
  display: block;
  border: 0;
  height: 40px;
  font-size: 18px;
  padding: 0 40px;
}

.registerButton.ant-btn {
  font-family: "Montserrat", arial;
  color: white;
  background: transparent;
  margin: 0 auto;
  display: block;
  border: 0;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.loginButton.ant-btn:hover,
.registerButton.ant-btn:focus {
  color: grey;
}
.registerButton.ant-btn:hover,
.registerButton.ant-btn:focus {
  background: transparent;
  color: white;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.inputDiv {
  background: rgba(0, 0, 0, 0.5);
  padding: 31px;
}

.logo {
  margin-top: 6vh;
  width: 100px;
  height: 100px;
  margin-left: 90px;
}
.background {
  background: url(../../../public/images/02_bg_gradient.png) no-repeat center center fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.link {
  margin-top: 5px;
  color: $base_color;
  font-family: "Montserrat", arial;
}

.centerDiv {
  margin: 0 auto;
  width: 286px;
  height: 500px;
}
