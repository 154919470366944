@import '../../config';

.settings-container {
  background-color: $backgroundColorMobile;
  padding-top: 60px;
  padding-bottom: 30px;
  &.admin {
    padding-top: 70px;
  }

  .settings-nav:hover,
  .settings-nav:focus {
    cursor: pointer;
    background-color: #eee;
  }

  .settings-nav i {
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
  }

  .settings-nav h3 {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $base_color;
    font-weight: 600;
  }

  .settings-main {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 16px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .settings-avatar {
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    position: relative;
    margin: 20px auto;
    text-align: center;
    canvas {
      background: silver;
    }
  }

  .settings-input {
    margin-bottom: 10px;
    border: 2px solid $base_color;
    border-radius: 16px;
    color: $base_color;
  }

  .settings-input:hover,
  .settings-input:focus {
    border: 2px solid $base_color;
  }

  .settings-input:-webkit-autofill,
  .settings-input:-webkit-autofill:focus,
  .settings-input:-webkit-autofill:hover {
    -webkit-text-fill-color: $base_color;
    border: 2px solid $base_color;
  }

  .settings-button {
    font-family: 'Montserrat', arial;
    color: white;
    background: $base_color;
    margin: 0 auto;
    margin-top: 5vh;
    display: block;
    border: 0;
    height: 40px;
    font-size: 14px;
    padding: 0 40px;
  }

  .settings-button:hover {
    color: grey;
    background-color: $base_color;
  }

  .settings-upload {
    margin-top: 0;
    border-radius: 32px;
    text-align: center;
  }

  .settings-upload .ant-upload {
    color: white;
    line-height: 40px;
  }

  .settings-upload .ant-upload:hover,
  .settings-upload .ant-upload:focus {
    color: grey;
    cursor: pointer;
  }

  .settings-username {
    display: none;
  }

  .settings-showErrors {
    color: red;
    margin-bottom: 10px;
  }

  .settings-top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Montserrat', arial;
  }

  .settings-button-container {
    max-width: 300px;
    margin: 0 auto;
  }

  .settings-user-pic,
  .settings-user-name {
    font-size: 10px;
    text-align: center;
  }

  .settings-user-pic {
    max-width: 200px;
  }

  .settings-profile-pic {
    display: block;
    margin: 0 auto;
    width: 80%;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .settings-user-name div {
    word-break: break-word;
  }

  .settings-meno {
    font-size: 20px;
    font-weight: bold;
    color: $base_color;
    margin-top: 20px;
  }

  .settings-mesto {
    font-size: 14px;
  }

  .photo-container {
    position: relative;
  }

  textarea {
    padding-bottom: 15px;
    color: rgb(128, 128, 128);
    font-size: 12px;
    border-color: $base_color;
    border-width: 2.5px;
    border-radius: 13px;
  }

  .settings-add-photo {
    padding-bottom: 10px;
  }

  .settings-choose-photo {
    background-color: white;
    border: 1px solid $base_color;
    border-radius: 10px;
    text-align: center;
    width: 30%;
    margin: 10px auto;
    padding: 5px;
    color: $base_color;
    cursor: pointer;
  }

  .settings-photoIcon {
    height: 5vh;
    display: block;
    margin: 10px auto;
  }

  .one-photo {
    width: 50vw;
    height: auto;
    margin: 5px;
  }

  .two-photos {
    width: 33vw;
    height: auto;
    margin: 5px;
  }

  .three-photos {
    width: 25vw;
    height: auto;
    margin: 5px;
  }
}

@media screen and (min-width: 768px) {
  .settings-container {
    background-color: $backgroundColor;
    padding-top: 50px;
    &.admin {
      padding-top: 100px;
    }

    .settings-button,
    .ant-upload {
      font-size: 18px;
    }

    textarea {
      font-size: 14px;
    }

    .report-issue {
      max-width: unset;
      width: 50%;
    }

    .one-photo,
    .two-photos,
    .three-photos {
      max-width: 250px;
      max-height: 250px;
      height: auto;
      width: auto;
    }
  }
}

body {
  background-color: $backgroundColor;
  @media screen and (max-width: 768px) {
    background-color: $backgroundColorMobile;
  }
}
