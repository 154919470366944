@import '../../config';

.omamaReport-main {
  padding: 0 10px 0;
  background-color: white;
  min-height: calc(100vh - 50px);
}

.omamaReport-generate-parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.omamaReport-generate-container {
  display: flex;
  justify-content: space-between;
  padding: unset;
  padding-bottom: 10px;
  max-width: 60vw;
  min-width: calc((173px * 2) + 2vw);
  gap: 2vw;
}

.omamaReport-generate-button {
  min-width: 173px;
  max-width: 25vw;
  width: 25vw;
  height: fit-content;
  min-height: 100%;
  text-align: center;
}

.omamaReport-download-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: balance;
}

@media screen and (max-width: 420px) {
  .omamaReport-generate-container {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
  .omamaReport-generate-button {
    width: 50%;
    max-width: unset;
    min-width: unset;
  }

  .omamaReport-generate-button.ant-btn {
    min-height: 100%;
    line-height: auto;
  }
}

@media screen and (max-width: 768px) {
  .omamaReport-generate-button.ant-btn {
    font-size: 14px;
  }
}
