@import '../../config';

.supervision-form-background {
  background-color: white;
  margin: 80px auto 0 auto;
  width: 100%;
}

.supervision-form-wrapper {
  overflow-x: hidden;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 40px;
  h2 {
    padding: 50px 0 20px;
    text-align: left;
    margin: 0 auto;
    color: inherit;
    font-size: 30px;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 40px 0;
    padding: 0;
  }
}

.supervision-form-grid {
  display: grid;
  grid-row-gap: 50px;
  grid-column-gap: 60px;
  margin: 0 0 20px;
  &.col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.col-2 {
    grid-template-columns: 1fr 1fr;
  }
  .form-item {
    width: 100%;
    input {
      width: 100%;
    }
  }

  .omamaDropdown .ant-dropdown-trigger {
    margin: 10px 0 0;
    width: auto;
    max-width: 300px;
  }
}

.supervision-form-wrapper .table {
  width: 100%;
  margin-bottom: 40px;
  .ant-table {
    overflow-x: unset;
    padding-bottom: 40px;
  }
  .ant-table-thead th:not(:first-child),
  .ant-table-row td:not(:first-child) {
    width: 100px;
  }
  .ant-table-row td:not(:first-child) {
    text-align: center;
  }
  .ant-table-row:not(.group-row) {
    .ant-table-row-expand-icon {
      display: none;
    }
  }
  .summary-row {
    td {
      color: #303030;
    }
  }
  .group-row {
    position: relative;
    height: 50px;
    td {
      color: white !important;
      background-color: $base_color !important;
    }
    td:first-child {
      height: 50px;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
    }
  }
}

.supervision-input-label {
  margin: 10px 0px 10px 0;
  font-size: 16px;
  color: #41aea6;
  font-weight: bold;
}

.supervision-read-only {
  font-weight: 800;
  font-size: 20px;
}

.ant-input.supervision-input,
.ant-input.ant-calendar-picker-input {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  height: 38px;
  width: 100%;
  // fixes weird hover behavior causing the input's width to jump
  border-right-width: 2.5px !important;
}

.supervision-footer {
  text-align: center;
}

.supervision-footer > button {
  border-color: #41aea6;
  box-shadow: 0 5px 5px 0 rgba(65, 174, 166, 0.1);
  border-radius: 24px;
  border-width: 2px;
  height: 50px;
  font-size: 1.2rem;
  background-color: #41aea6;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
}

.supervision-datepicker {
  .ant-calendar-picker-input {
    border-color: #41aea6;
    border-radius: 36px;
    border-width: 2.5px;
    color: gray;
    font-weight: bold;
    font-size: 16px;
    height: 38px;
  }
}

@media screen and (max-width: 800px) {
  .supervision-form-background {
    margin: 50px auto 0 auto;
  }
  .supervision-form-wrapper {
    .supervision-form-grid {
      grid-column-gap: 30px;
      grid-row-gap: 10px;
      &.col-2 {
        grid-template-columns: 1fr;
      }
      &.col-3 {
        grid-template-columns: 1fr 1fr;
      }
    }
    .table {
      .ant-table-thead th:not(:first-child),
      .ant-table-row td:not(:first-child) {
        width: 60px;
      }
      .ant-table-row td:first-child {
        font-size: 14px;
      }
      .ant-table-header-column {
        & > div {
          transform: rotate(-90deg);
          white-space: nowrap;
          height: 150px;
          position: relative;
          transform-origin: top right;
          left: -30px;
          top: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .supervision-form-wrapper {
    .table {
      .ant-table-thead th:not(:first-child),
      .ant-table-row td:not(:first-child) {
        width: 35px;
      }
      .ant-table-row td:first-child {
        font-size: 12px;
      }
      .ant-table-header-column {
        & > div {
          transform: rotate(-90deg);
          white-space: nowrap;
          height: 150px;
          position: relative;
          transform-origin: top right;
          left: -20px;
          top: 130px;
        }
      }
    }
    .supervision-form-grid {
      &.col-2,
      &.col-3 {
        grid-template-columns: 1fr;
      }
    }
  }
}
