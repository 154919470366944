.rented-accessory-history-table {
  background-color: white;
  border: 1px solid #ecf3f3;
  border-radius: 12px;
  overflow: hidden;
}

.rented-accessory-history-table tr th {
  font-weight: 700;
  background-color: white;
  color: #091817;
  border-bottom: 1px solid #ecf3f3;
}

.rented-accessory-history-table tr td {
  font-weight: normal;
  color: #091817;
  border-top: 1px solid #ecf3f3;
  border-bottom: 1px solid #ecf3f3;
}

.rented-accessory-history-table {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 115px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    min-width: 110px;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    min-width: 200px;
  }
}

@media screen and (max-width: 768px) {
  .rented-accessory-history-table {
    overflow-x: scroll;
  }
}
