@import '../config';

.loading {
  color: $base_color;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: block;
}

.content {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 90% !important;
  }
}
