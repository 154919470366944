@import '../../../config';

.preschool-screening-results-table {
  max-width: 720px;
  text-align: center;
  margin: auto;
  padding: 20px;
  font-weight: bold;
}

.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

.preschool-screening-results-table .ant-descriptions-view {
  border: 1px solid $base_color;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
}

.preschool-screening-results-table .ant-descriptions-item-content {
  padding: 8px 24px;
  background-color: white;
}

.preschool-screening-results-table .ant-descriptions-item-label {
  padding: 8px 24px;
  background-color: white;
  font-weight: bold;
  color: inherit;
  border-right: 1px solid #41aea6;
}

.preschool-screening-results-table .first-row {
  color: $base_color;
}

.preschool-screening-results-table .ant-descriptions-row:first-child {
  color: $base_color;
  background-color: #fafafa;
  border-bottom: 1px solid #41aea6;
}

.preschool-screening-results-table .ant-descriptions-row:first-child .ant-descriptions-item-label {
  color: $base_color;
  background-color: #fafafa;
  padding: 16px 24px;
}

.preschool-screening-results-table-milestone {
  max-width: 400px;
  text-align: center;
  margin: auto;
  padding: 0;
  padding-bottom: 10px;
  padding-top: 20px;
  font-weight: bold;
}

.preschool-screening-results-table-milestone {
  .preschool-screening-category {
    padding-top: 8px;
  }

  .ant-progress-inner {
    background-color: #d6e1e0;
  }

  .ant-progress-bg {
    background-color: #41aea6;
    border-radius: 12px;
  }

  .no-border .ant-progress-bg {
    border-right: none;
  }
}

@media screen and (max-width: 768px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .preschool-screening-results-table .preschool-screening-category {
    padding-top: 15px;
  }

  .preschool-screening-results-table-milestone {
    font-size: 12px;
  }
}
