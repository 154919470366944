.accessory-detail-item .ant-descriptions-item-label {
  color: #41aea6;
  font-weight: bold;
}

.accessory-detail-item .ant-descriptions-item-content {
  color: black;
  display: block;
  font-size: initial;
  padding-top: 8px;
}

.ant-descriptions-row > td.accessory-detail-item {
  display: block;
}

.accessory-detail-textarea {
  max-width: 600px;

  div {
    width: 100%;
  }

  textarea {
    margin-bottom: 25px;
    box-shadow: 0px 9px 40px -19px rgba(0, 0, 0, 0.75);
    color: rgb(128, 128, 128);
    font-family: Montserrat, arial;
    border-color: #41aea6;
    border-width: 2.5px;
    border-radius: 13px;
  }
}

.accessory-status-detail-buttons {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .accessory-status-detail-buttons {
    width: 70%;
  }
}
