@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

@media screen and (min-width: 750px) {
  .mentorPlanMobileView {
    display: none;
  }
  .desktopView {
    display: block;
    .admin-omama-actions-week {
      &-rangePicker {
        width: 65%;
        margin-right: 25px;
      }
      .monthData {
        text-align: center;
      }
    }
    .mentor-actions-table {
      .ant-table-thead > tr > th:nth-child(1) {
        min-width: 130px;
      }
      .ant-table-thead > tr > th:nth-child(2) {
        min-width: 80px;
      }
      .ant-table-thead > tr > th:nth-child(4) {
        min-width: 100px;
      }
      .ant-table-thead > tr > th:nth-child(5) {
        min-width: 300px;
      }
      tbody tr td:nth-child(5) > div {
        max-height: 75px;
        overflow: auto;
      }
      .ant-table-thead {
        height: 68px;
      }
      .ant-table-tbody > tr {
        height: 48px;
      }
    }
  }
}

@media screen and (max-width: 1470px) {
  .admin-omama-actions-week {
    &-rangePicker {
      margin-right: 0 !important;
    }
    .adminClient-button {
      margin-left: 5px;
      font-size: unset;
    }
  }
  .monthLabel {
    display: none;
  }
  .monthData {
    text-align: center;
    min-width: 90px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1030px) {
  .admin-supervisor-actions-week {
    &-rangePicker {
      width: 50% !important;
    }
  }
}

@media screen and (max-width: 750px) {
  .desktopView {
    display: none;
  }

  .ant-calendar-range {
    width: 280px !important;
    &-part {
      width: 100% !important;
    }
  }
  .ant-calendar-date-input-wrap,
  .ant-calendar-range-middle {
    display: none;
  }

  .mentorPlanMobileView {
    display: block;

    .container {
      padding-top: 0;

      &-inner {
        &-options {
          display: flex;
          justify-content: space-between;

          &-rangePicker {
            width: 55%;
          }

          &-monthPicker {
            display: flex;
            align-items: center;
            color: $base_color;
            width: 30%;
            margin-right: 10px;

            &-icon {
              position: relative;
              top: -2px;
            }

            &-text {
              text-align: center;
            }
          }

          &-addAction {
            width: 15%;
          }
        }

        &-toDoList {
          margin: 20px 10px;
          display: flex;
          flex-direction: column;

          &-toDo {
            border-bottom: 1px solid $base_color;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            &-date {
              flex: 30%;
            }

            &-time {
              flex: 20%;
              color: $base_color;
            }

            &-title {
              flex: 45%;
            }

            &-arrow {
              flex: 5%;
              color: $base_color;
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }
}

.ant-select-dropdown-menu-item-active {
  color: #41aea6 !important;
}

.changeActionContainer {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.timeSpentEdit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.timeSpentInput {
  width: 8ch;
}

.activities-main .adminClient-form {
  margin-bottom: 40px;
}
