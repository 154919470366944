@import '../../config';

.accessories-main {
  background-color: $backgroundColor;
  padding: 40px;
  padding-top: 120px;
  min-height: 100vh;
  text-align: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    background-color: $backgroundColorMobile;
  }
}

.accessories-choose-table {
  display: flex;
  justify-content: space-around;
  width: 75%;
  margin: 0 auto;
}

//.accessories-main .ant-btn:hover,
.accessories-main .ant-btn:focus {
  background-color: #41aea6;
  color: white;
}

.filter-checkbox {
  padding: 8px 16px;
  border: 1px solid black;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  background-color: white;
  font-weight: bold;
}

.accessories-active-button {
  border-color: #41aea6;
  border-radius: 24px;
  border-width: 2px !important;
  height: 50px;
  font-size: 1.2rem;
  background-color: #41aea6;
  color: white;
  font-weight: bold;
  margin-bottom: 25px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.accessories-active-button:hover {
  border-color: #2b8c84;
  background-color: #2b8c84;
  color: white;
}

.accessories-inactive-button {
  border-color: #060606;
  border-radius: 24px;
  border-width: 2px !important;
  height: 50px;
  font-size: 1.2rem;
  background-color: white;
  color: #060606;
  font-weight: bold;
  margin-bottom: 25px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.accessories-inactive-button:hover {
  background-color: #d6fcf9;
  color: #060606;
  border-color: #060606;
}

.accessories-deliver-button {
  background-color: #41aea6;
  color: white;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 16px;
  border-radius: 29px;
}

.accessories-deliver-button:hover {
  background-color: #2b8c84;
  color: white;
  border-color: #2b8c84;
}

.accessories-add-button {
  background-color: #41aea6;
  color: white;
  padding: 0 16px;
  float: right;
  border-radius: 29px;
  font-size: 19px;
  margin-left: 50px;
  height: 40px;
  width: 150px;
}

.accessories-main .table-manipulation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.accessories-main .table-manipulation.missing-mobile {
  display: none;
}

.accessories-main .table-manipulation.all-accessories-mobile {
  display: none;
}

.ant-input.accessories-input {
  height: 40px;
  padding-left: 20px;
  border: 2px solid $base_color;
  border-radius: 24px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1rem;
  font-weight: bold;
  color: gray;
}

.accessories-input.search {
  min-width: 250px;
  width: 30%;
  max-width: 350px;
}

.missing-desktop .missing-search-input-container {
  min-width: 250px;
  width: 30%;
  max-width: 350px;
}

.missing-search-input-container .ant-input.accessories-input.search {
  width: 100%;
}

.accessories-table-mobile {
  display: none;
}

.accessory-image {
  max-height: 100px;
}

.missing-accessories-table-desktop {
  overflow: hidden;
}

.missing-accessories-table-desktop,
.accessories-table-desktop {
  .ant-table-thead {
    height: 68px;
  }

  .ant-table-tbody > tr {
    height: 68px;
  }
}

.missing-accessories-table-desktop {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(5) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(6) {
    min-width: 140px;
  }
  .ant-table-thead > tr > th:nth-child(7) {
    min-width: 210px;
  }
  tbody tr {
    cursor: default;
  }
}

@media screen and (max-width: 768px) {
  .accessories-main {
    padding: 10px;
    padding-top: 100px;
  }

  .accessories-choose-table {
    width: 100%;
  }

  .accessories-active-button {
    font-size: 14px;
    margin-bottom: 25px;
    padding: 0 10px;
  }

  .accessories-inactive-button {
    font-size: 14px;
    margin-bottom: 25px;
    padding: 0 10px;
  }

  .accessories-add-button {
    display: none;
  }

  .accessories-table-desktop {
    display: none;
  }

  .missing-accessories-table-desktop {
    display: none;
  }

  .accessories-table-mobile {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }

  .accessories-table-mobile table tr th {
    font-size: 12px;
    font-weight: normal;
    color: $base_color;
  }

  .accessories-table-mobile table tr td {
    font-size: 12px;
    font-weight: normal;
    color: black;
  }

  .ant-input.accessories-input.search {
    height: 35px;
    width: 40%;
    min-width: 100px;
    padding-left: unset;
    border: unset;
    border-bottom: 1px solid $base_color;
    border-radius: 0px;
    box-shadow: unset;
    font-size: 16px !important;
    line-height: 21px;
    font-weight: normal;
    color: $base_color;
    &::placeholder {
      color: $base_color;
      font-size: 16px;
      line-height: 21px;
    }
  }

  .accessories-table-mobile-icon {
    color: #41aea6;
    padding-bottom: 8px;
  }

  .accessories-main .missing {
    display: block;
    padding: 0px;
  }

  .missing-search-input-container {
    width: 50%;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .missing-search-input-container .ant-input.accessories-input.search {
    width: 100%;
  }

  .filter-checkbox {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    display: flex;
    font-weight: normal;
    margin-bottom: 15px;
  }

  .accessories-main .table-manipulation.missing-desktop {
    display: none;
  }

  .accessories-main .table-manipulation.missing-mobile {
    display: block;
    padding: 0;
    padding-top: 20px;
  }

  .accessories-main .table-manipulation.all-accessories-desktop {
    display: none;
  }

  .accessories-main .table-manipulation.all-accessories-mobile {
    display: flex;
    padding: 20px;
  }
}
