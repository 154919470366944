@import '../_config.scss';

.galleryFolder {
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 6px;
  font-weight: 600;
  color: $base_color;
  margin: 20px;
  cursor: pointer;
}
