@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.day {
  border-bottom: 1px solid $base_color;
  margin: 0 15px;
  padding-bottom: 15px;
}

.date {
  width: 55px;
  text-align: center;
  color: $base_color;
  margin-left: 10px;
  float: left;
}

.den {
  font-size: 0.6em;
  text-transform: capitalize;
}

.action {
  margin-left: 20px;
  display: inline-block;
}

.cas {
  min-width: 40px;
  color: grey;
}
.client {
  color: $base_color;
  margin-left: 10px;
  word-break: break-all;
  max-width: 58vw;
  cursor: pointer;
}

.client.canceled {
  color: darkgray;
  span {
    text-decoration: line-through;
  }
}

.notDone {
  color: #e44848;
}

.clubStyle {
  color: #778ffc !important;
}

@media screen and (max-width: 385px) {
  .client {
    max-width: 48vw;
  }
}
