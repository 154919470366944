@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../config';

.action-button-cancel {
  margin: 0 5px;
  font-size: 1em;
  padding: 4.7px 10px;
  border-radius: 15px;
  font-weight: 100;
  background-color: #41aea6;
  color: white;
  display: inline-block;
}
.action-button-save {
  margin: 20px 5px 30px;
  font-size: 1em;
  background-color: #41aea6;
  color: white;
  display: inline-block;
}

.action-modal-picker {
  width: 100%;
  .ant-time-picker-input {
    border-color: #41aea6;
    border-width: 2px;
    border-radius: 36px;
    padding: 4px 0 4px 23px;
  }
}
.ant-btn:focus.action-button-save,
.ant-btn:hover.action-button-save {
  color: $base_color;
  border-color: $base_color;
}

.changeCommentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.changeCommentInput {
  width: 100%;
  max-width: 400px;
  min-width: 200px;
}

.photoSectionGroup {
  margin-bottom: 25px;
}

.addActionPhotoSection {
  border-bottom: 1px solid $base_color;
  padding-bottom: 15px;
}

.showActionPhotoSection {
  border-bottom: 1px solid $base_color;
  padding-bottom: 15px;
}
