@import '../../config';

.ant-btn.report-button {
  min-height: 40px;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 25px;
  background-color: $base_color;
  color: white;
  border: 1px solid $base_color;
  height: unset;
  line-height: unset;
}

.ant-btn.report-button:hover {
  border-color: #2b8c84;
  background-color: #2b8c84;
  color: white;
}

.report-container {
  margin-left: 3vw;
  margin-right: 3vw;
  padding-top: 8vh;
  .ant-calendar-picker .anticon {
    color: $base_color;
    margin-top: -9px;
    top: 50%;
  }
}

/* Tabs design */
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
  background-color: transparent;
  font-size: 1.2em;
}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-active {
  border-bottom: solid 2px #41aea6 !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border-color: transparent;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border-bottom-color: #41aea6;
}
.ant-tabs-bar {
  border-bottom: none;
}
.ant-btn.report-button.report-csv {
  display: inline-block;
  margin-right: 20px;
}

.timeReport {
  .timeReportField {
    margin-top: 10px;
  }
}

.report-select {
  min-width: 275px;
  width: 305px;
  max-width: 500px;
}

.report-select .ant-select-selection,
.report-select.text {
  border: 2px solid #41aea6;
  border-radius: 14px;
}

.report-label {
  display: inline-block;
  margin-right: 15px;
  color: #9999a0;
  font-weight: 700;
}

.adminReport_datepicker {
  border-color: #41aea6;
  border-radius: 36px;
  border-width: 2.5px;
  color: gray;
  font-weight: bold;
  margin-bottom: 15px;
  width: 382px;
  .anticon {
    margin-top: -9px;
    top: 50%;
  }
}

.screeningResultsReport {
  display: flex;
  flex-direction: column;
  .generateButton,
  .report-select {
    margin-top: 15px;
  }

  .report-select,
  .ant-calendar-picker {
    width: 238px;
    min-width: 0;
  }
}

.no-data-notification {
  color: red;
  margin-left: 10px;
}

.bottomPageReport {
  margin-bottom: 40px;
}

.choose-region {
  padding-bottom: 16px;
}

.reportsWrapper {
  width: 400px;
}

@media screen and (max-width: 430px) {
  .adminReport_datepicker {
    width: 100%;
  }
}
