@import '../../../config';

.saveButton {
  min-width: 45vw;
  margin-left: auto;
  margin-right: auto;
  background-color: $base_color;
  color: white;

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    background-color: #82b3af;
  }
}

.screening-list-item {
  margin-left: 4vw;
  margin-right: 4vw;
}

.screening-screen {
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-left: 4vw;
  padding-right: 4vw;
  flex: 1;
  margin-top: 60px;
  align-items: center;
  display: inline-flex;
  padding-bottom: 70px;
  background-color: white;
}
