@import '../config';

.plan-week-picker {
  width: 300px;
  padding: 0 5px;
}

.plan-week-picker.ant-calendar-picker .ant-input {
  border-color: #060606;
  border-radius: 36px;
  border-width: 1px !important;
  padding-left: 15px;
  color: #060606;
  font-weight: normal;
  min-height: 40px;
  cursor: pointer;
}

.plan-week-picker.ant-calendar-picker .anticon-calendar {
  padding-right: 20px;
  color: #060606;
  width: 14px;
  top: 46%;
  cursor: pointer;
}

.plan-week-picker.ant-calendar-picker .anticon-close-circle {
  display: none;
}

@media screen and (max-width: 768px) {
  .plan-week-picker {
    width: 250px;
  }

  .plan-week-picker.ant-calendar-picker .anticon-calendar {
    padding-right: 12px;
  }

  .plan-week-picker.ant-calendar-picker .ant-input {
    font-size: 14px !important;
    padding-left: 12px;
  }
}
