@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../../config';

div span.ant-calendar-picker span.ant-calendar-picker-input.ant-input {
  border: 1px solid #41aea6;
  .anticon.anticon-calendar.ant-calendar-picker-icon {
    color: #41aea6;
  }
}

.showJustOnMobile {
  display: none;
}

.adminBox {
  background-color: $backgroundColor;
  margin: 0 auto;
  width: 95vw;
  min-height: calc(100vh - 80px);
}

.admin-userDetail .admin-rightside {
  overflow: hidden;
  flex: 1;
  text-align: -webkit-center;
  padding: 0 25px;
  &-header {
    //width: calc((95vw - 70px) * 0.8);
    cursor: pointer;
  }
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin-omama-editprofile {
  width: 95%;
}

.admin-omama-editprofileBtn {
  text-align: center;
  margin-top: 20px;
  display: block;
}

.ant-btn:hover.admin-omama-editprofile,
.ant-btn:focus.admin-omama-editprofile {
  color: $base_color;
  border-color: $base_color;
}

.admin-omama-stats {
  background-color: white;
  padding: 15px 20px;
  border-radius: 25px;
}

.admin-omama-bottompanel {
  padding: 15px 20px;
  border-radius: 25px;
}

.admin-omama-bottompanel .plan-main {
  background-color: unset;
}

.admin-omama-bottompanel .actionContainer:hover {
  background-color: unset;
}

.admin-omama-bottompanel.omama-profile {
  padding: 0;
}

.admin-omama-stats,
.admin-omama-bottompanel {
  width: calc((95vw - 70px) * 0.8);
}

.admin-omama-stats__workedhours {
  width: 30%;

  display: inline-block;
  text-align: center;
  border-right: 1px solid $base_color;
  .workedhours__count {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $base_color;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-weight: bold;
    font-size: 1.1rem;
  }
}

.admin-omama-stats__counters {
  width: 70%;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.admin-omama-stats__navigation {
  padding: 0 10px;
  padding-top: 1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  * {
    font-family: 'Montserrat', arial, serif;
    font-weight: bold;
    font-size: 1.3em;
    color: $base_color;
  }
}

.admin-omama-submenu {
  display: flex;
  gap: 32px;
  align-items: center;
  background-color: white;
  padding: 32px;
  border-radius: 25px;
  margin-bottom: 25px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d6e1e0;

    li {
      cursor: pointer;
      .submenu-item {
        transition: none;
        padding: 0 5px;
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
        color: #060606;
        transition: color 0.3s;
      }
    }

    li:hover .submenu-item,
    li.active .submenu-item,
    li:focus .submenu-item {
      color: $base_color;
      padding-bottom: 3px;
      border-bottom: 2px solid $base_color;
      transition: color 0.3s;
    }
  }
}

.activeHeader {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
  color: $base_color;
  margin-left: 1.66%;
  padding-top: 10px;
}

.inactiveHeader {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
  color: $base_color;
  margin-left: 1.66%;
  padding-top: 20px;
  border-top: 1px solid $base_color;
}

.adminOmamaList.active,
.adminOmamaList.inactive {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  justify-content: space-between;

  .clientLeave {
    display: block;
  }

  .AdminPersonCard {
    width: 45%;
  }
}

// Sub Menu - Akcie
.admin-omama-actions-week {
  margin-bottom: 10px;
  display: flex;
  color: $base_color;
  align-items: center;
  &-rangePicker {
    width: 65%;
    margin-right: 25px;
  }

  .admin-omama-actions-week__changeWeek {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.1rem;

    * {
      display: flex;
    }
  }
}

.admin-omama-actions-week__changeWeek {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1rem;
  color: #060606;

  * {
    display: flex;
  }

  .anticon svg {
    background-color: white;
    border-radius: 50%;
  }
}

.admin-omama-bottompanel .adminClient-form-group {
  flex-wrap: wrap;
}

.omama-actions-table {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 90px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    min-width: 80px;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    min-width: 150px;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    min-width: 130px;
  }
  .ant-table-thead {
    height: 68px;
  }
  .ant-table-tbody > tr {
    height: 48px;
  }
}

.omama-supervisions-table {
  .ant-table-thead > tr > th:nth-child(1) {
    min-width: 130px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    min-width: 80px;
  }
  .ant-table-thead {
    height: 68px;
  }
  .ant-table-tbody > tr {
    height: 48px;
  }
}

.adminOmama-spentTimePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-weight: bold;
    margin: 10px 5px;
    color: #41aea6;
  }
  .ant-time-picker {
    width: 230px;
  }
  .ant-time-picker-input {
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
  }
  #timeSpent {
    width: 80px;
    border-color: $base_color;
    border-width: 2px;
    border-radius: 36px;
  }
}

.omamaReportAdmin-main {
  padding: 0 10px 0;
  * {
    text-align: center;
  }
}

.actionsOmamaMobileView {
  display: none;
}

.admin-userDetail .ant-descriptions-row .ant-descriptions-item-content {
  background-color: white;
}

@media screen and (max-width: 1470px) {
  .admin-omama-actions-week {
    &-rangePicker {
      margin-right: 0 !important;
    }
    .adminClient-button {
      margin-left: 5px;
      font-size: unset;
    }
  }
  .monthLabel {
    display: none;
  }
  .monthData {
    text-align: center;
    min-width: 90px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .admin-generalUserInfo {
    display: none;
  }

  .admin-userDetail {
    flex-direction: column;
    &-panelCont {
      width: 100%;
    }
  }

  .admin-omama-panel-mobile_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .admin-omama-name {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      font-weight: 800;
    }
    .admin-omama-role {
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #41aea6;
      font-weight: 800;
    }
    .admin-omama-inProgram {
      display: flex;
      align-items: center;
      margin-top: unset;
      font-size: 14px;
      line-height: 21px;
      font-style: normal;
      span {
        margin: unset;
        margin-left: 3px;
        font-size: 14px;
        line-height: 21px;
        font-style: normal;
      }
    }
    .admin-omama-status {
      color: $base_color;
    }
  }

  .admin-omama-tabsMobile {
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
    &_row {
      border-bottom: 1px solid $base_color;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        margin-right: 17px;
        color: $base_color;
      }
      span {
        padding-left: 10px;
        font-weight: 800;
        font-size: 20px;
        line-height: 21px;
        color: $base_color;
      }
    }
    &_row:last-of-type {
      margin-bottom: 0;
    }
  }

  .tab_modal {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    padding-top: 50px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    padding-bottom: 800px;
    background-color: white;
    z-index: 999;
    &_menu {
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
      &-closeIcon {
        margin-top: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .admin-omama-clientCountMobile {
    display: flex !important;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 27px;
    border-bottom: 1px solid #808080;
    &-cont {
      display: flex;
      flex-direction: column;
      h3 {
        font-weight: 800;
        font-size: 14px;
        line-height: 21px;
      }
      &-row {
        display: flex;
        &-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 42px;
          &-value {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 31px;
            height: 31px;
            border-radius: 50% 50%;
            background: $base_color;
            color: white;
            margin-right: 11px;
            font-weight: 800;
            font-size: 14px;
            line-height: 21px;
          }
          span {
            color: #333333;
          }
        }
      }
    }
  }

  .omamaReportAdmin-main {
    height: calc(100vh - 50px - 40px);
    overflow-y: scroll;
  }

  .adminOmamaList.active,
  .adminOmamaList.inactive {
    flex-direction: column;
  }

  .adminOmamaClientList {
    margin-top: 20px;
    padding: 0 5px;
    height: calc(100vh - 50px - 40px);
    overflow-y: scroll;
  }

  .inactiveHeader {
    border-top: unset;
  }

  .admin-omama-stats,
  .admin-omama-bottompanel {
    width: unset;
    padding: 0 20px 20px 20px;
    border-radius: unset;
    .AdminPersonCard {
      height: 84px;
      width: 100% !important;
      .AdminPersonCardImage {
        padding-right: 10px;
      }
      .AdminPersonCardImageInfo {
        padding-left: 10px;
        font-weight: normal;
      }
      .AdminPersonCardArrow {
        color: $base_color !important;
        font-size: 18px;
      }
    }
  }

  .tab_modal .omamaSupervisions {
    padding: 0;
    width: 100vw;
    margin-left: -20px;
    &_list {
      margin-top: 20px;
      height: calc(100vh - 120px - 40px);
      overflow-y: scroll;
    }
  }

  .actionsOmamaMobileView {
    display: block;

    &-container {
      &-inner {
        &-options {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          &-rangePicker {
            width: 50%;
            height: 38px;
          }
          &-monthPicker {
            display: flex;
            align-items: center;
            color: $base_color;
            width: 33%;
            margin: 0 5px;
            &-icon {
              position: relative;
              top: -2px;
            }
            &-text {
              text-align: center;
            }
          }
          &-addAction {
            width: unset;
          }
        }
        &-toDoList {
          display: flex;
          flex-direction: column;
          //padding-top: 10px;
          margin-top: 20px;
          height: calc(100vh - 120px - 40px);
          overflow-y: scroll;
          &-toDo {
            border-bottom: 1px solid $base_color;
            padding: 7px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            &-upperCont {
              padding-top: 5px;
              &-title {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: $base_color;
              }
            }
            &-lowerCont {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding-top: 5px;
              font-size: 13px;
              &-date {
                flex: 35%;
              }
              &-time {
                flex: 25%;
                color: $base_color;
              }
              &-status {
                flex: 45%;
                //color: $base_color;
              }
              &-arrow {
                flex: -5%;
                color: $base_color;
                position: relative;
                top: -2px;
              }
            }
          }
        }
      }
    }
  }

  .doNotShowOnMobile {
    display: none;
  }

  .showJustOnMobile {
    display: block;
  }

  .admin-userDetail .omamaWeek {
    padding-left: 5px;
  }

  .admin-userDetail .user_activities {
    margin-left: 0;
  }
}

.actionContainer {
  text-align: left;
}

.actionContainer:hover {
  cursor: pointer;
  background-color: #fbfbfb;
}

.white-background {
  background-color: white;
}

.weekPicker {
  margin: 20px 0 20px 5px;
}

.week-selector-container {
  display: flex;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .admin-omama-submenu.just-omama {
    padding: 32px 16px;
    gap: 8px;
    flex-wrap: wrap;

    ul {
      gap: 8px;
    }

    ul li .submenu-item {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .admin-omama-bottompanel .adminClient_datepicker {
    max-width: 275px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .admin-omama-submenu.just-omama {
    padding: 32px 16px;
    gap: 16px;

    ul {
      gap: 16px;
    }

    ul li .submenu-item {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .admin-omama-bottompanel .adminClient_datepicker {
    max-width: 275px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .admin-omama-submenu.just-omama {
    padding: 32px 20px;
    gap: 24px;

    ul {
      gap: 16px;
    }
  }

  .admin-omama-bottompanel .adminClient_datepicker {
    max-width: 300px;
  }
}

@media screen and (min-width: 769px) {
  .weekPicker {
    display: flex;
    margin: 20px 0 20px 15px;
  }
}
