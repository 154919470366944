@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '../../config';

.container {
  padding-top: 50px;
  min-height: calc(100vh - 50px);
  background-color: white;

  &-inner {
    padding-top: 20px;
    min-height: calc(100vh - 50px);

    .mesiac {
      margin: 0 20px;
      padding: 20px 0 0px 5px;
      color: black;
      font-weight: bold;
    }

    .message {
      margin: 0 20px;
      padding: 30px 0 20px 0;
      color: black;
    }

    .picker {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 20px;

      .datepicker {
        position: relative;
        width: 150px;
        //height: 19px;

        &-calendar {
          padding-bottom: 3px;
          width: 17px;
          height: 19px;
        }

        &-button {
          padding-left: 5px;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          color: $base_color;
        }

        &-icon {
          padding-left: 5px;
          position: absolute;
          top: -2.5px;
          color: $base_color;
        }

        &-input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          margin: 0;
          padding: 0;
          //box-sizing: border-box;

          &::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            cursor: pointer;
          }
        }
      }
      .yearpicker {
        display: flex;
        flex-direction: row;
        color: $base_color;

        &-year {
          padding: 0 10px;
        }

        .icon {
          position: relative;
          top: -2px;
        }
      }
    }
  }
}
